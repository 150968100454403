import { IconButton, Tooltip, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

declare global {
  interface Window {
    Beamer: any;
    beamer_config: string;
  }
}
export default function GlobalBeamerButton() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <script>
          {`window.beamer_config = {
        selector: '#beamerButton',
		product_id: 'OQgSWzTY60689' //DO NOT CHANGE: This is your product code on Beamer
	}`}
        </script>
        <script
          type="text/javascript"
          src="https://app.getbeamer.com/js/beamer-embed.js"
          defer
        ></script>
      </Helmet>
      <Tooltip
        title={
          <Typography variant="body1" sx={{ color: 'white' }}>
            {t('beamer.tooltip.title', {
              defaultValue: 'Product updates',
            })}
          </Typography>
        }
        placement="bottom"
      >
        <IconButton id="beamerButton">
          <Icon icon="bell" size={20} sx={{ color: 'gray.80' }} />
        </IconButton>
      </Tooltip>
    </>
  );
}
