import { createQueryKeys } from '@lukemorales/query-key-factory';
import { UseErrorBoundary, useQuery } from '@tanstack/react-query';

import { useAxios } from '@/api/axiosClient';
import { GetUserWorkSpacesResponse } from '@/api/types';

export const tenentHubKeys = createQueryKeys('tenentHub', {
  getUserWorkspaces: null,
});

type UseGetWorkspacesQueryParams = {
  staleTime?: number;
  cacheTime?: number;
  retry?: boolean;
  useErrorBoundary?:
    | UseErrorBoundary<
        GetUserWorkSpacesResponse,
        unknown,
        GetUserWorkSpacesResponse,
        any
      >
    | undefined;
};
export const useGetUserWorkspacesQuery = (
  options?: UseGetWorkspacesQueryParams,
) => {
  const axiosClient = useAxios();
  return useQuery({
    queryKey: tenentHubKeys.getUserWorkspaces,
    queryFn: async () => {
      const response = await axiosClient.post<GetUserWorkSpacesResponse>(
        '/v1/tenant-hub/UserWorkspaces/GetUserWorkspaces',
        {},
        {
          baseURL: import.meta.env.VITE_SLEEKFLOW_API_BASE_URL,
        },
      );
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    },
    useErrorBoundary: options?.useErrorBoundary,
    retry: options?.retry,
    staleTime: options?.staleTime,
    cacheTime: options?.cacheTime,
  });
};
