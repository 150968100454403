export const SUBSCRIPTION_TIER = {
  free: 0,
  pro: 1,
  premium: 2,
  enterprise: 3,
  addOn: 4,
  agent: 5,
};

export const PLAN_NAMES_REGEX = {
  freemium: {
    subscriptionTier: SUBSCRIPTION_TIER.free,
    regex: /sleekflow_freemium|sleekflow_free/,
    name: 'startup',
  },
  proMonthly: {
    subscriptionTier: SUBSCRIPTION_TIER.pro,
    regex: /sleekflow_v\d+_pro(?:_monthly)?/,
    name: 'pro',
    period: 'monthly',
  },
  proYearly: {
    subscriptionTier: SUBSCRIPTION_TIER.pro,
    regex: /sleekflow_v\d*_pro_yearly/,
    name: 'pro',
    period: 'yearly',
  },
  premiumMonthly: {
    subscriptionTier: SUBSCRIPTION_TIER.premium,
    regex: /sleekflow_v\d+_premium(?:_monthly)?/,
    name: 'premium',
    period: 'monthly',
  },
  premium: {
    subscriptionTier: SUBSCRIPTION_TIER.premium,
    regex: /sleekflow_v\d*_premium_yearly/,
    name: 'premium',
    period: 'yearly',
  },
  enterprise: {
    subscriptionTier: SUBSCRIPTION_TIER.enterprise,
    name: 'enterprise',
    regex: /sleekflow_enterprise/,
  },
} as const;

export type SubscriptionPlanName =
  typeof PLAN_NAMES_REGEX[keyof typeof PLAN_NAMES_REGEX]['name'];

export const LATEST_PLAN_VERSION = 9;
