export const SIGNALR_EVENTS = {
  classic: {
    onBackgroundTaskStatusChange: 'OnBackgroundTaskStatusChange',
    onMessageReceived: 'OnMessageReceived',
    onMessageStatusChanged: 'OnMessageStatusChanged',
    onConversationStatusChanged: 'OnConversationStatusChanged',
    onConversationAdded: 'OnConversationAdded',
    onConversationAssigneeDeleted: 'OnConversationAssigneeDeleted',
    onConversationNoteReceived: 'OnConversationNoteReceived',
    onConversationDeleted: 'OnConversationDeleted',
    onConversationAdditionalAssigneeDeleted:
      'OnConversationAdditionalAssigneeDeleted',
    onConversationAdditionalAssigneeAdded:
      'OnConversationAdditionalAssigneeAdded',
    onConversationTyping: 'OnConversationTyping',
    onConversationAssigneeChanged: 'OnConversationAssigneeChanged',
    onRemarksReceived: 'OnRemarksReceived',
    onStaffInfoUpdated: 'OnStaffInfoUpdated',
    onUserProfileFieldFormatChanged: 'OnUserProfileFieldFormatChanged',
    onUserProfileDeleted: 'OnUserProfileDeleted',
    onStripePaymentStatusUpdated: 'OnStripePaymentStatusUpdated',
    onImportCompleted: 'OnImportCompleted',
    // Dev Note: The typo is intentional from the backend
    pushNotifications: 'PushNotitions',
  },
  reliableReceiveMessage: 'Reliable.ReceiveMessage',
  reliableAckMessage: 'Reliable.AckMessage',
  associationsGroupUnassociated: 'Associations.GroupUnassociated',
  associationsGroupAssociated: 'Associations.GroupAssociated',
  associationsUserUnassociated: 'Associations.UserUnassociated',
  associationsUserAssociated: 'Associations.UserAssociated',
  sessionsLeftSessionMessage: 'Sessions.LeftSessionMessage',
  sessionsJoinedSession: 'Sessions.JoinedSession',
  exceptionsAlreadyJoinedSessionException:
    'Exceptions.AlreadyJoinedSessionException',
  exceptionsSessionNotFoundException: 'Exceptions.SessionNotFoundException',
} as const;

export const SIGNALR_INVOKE_EVENTS = {
  classic: {
    addToGroup: 'AddToGroup',
  },
  sessionsInitSession: 'Sessions.InitSession',
  sessionsJoinSession: 'Sessions.JoinSession',
  associationsAssociateGroup: 'Associations.AssociateGroup',
  associationsAssociateUser: 'Associations.AssociateUser',
};

export const STANDARD_SIGNALR_EVENTS = {
  onBackgroundTaskStatusChange: 'OnBackgroundTaskStatusChange',
  onMessageReceived: 'OnMessageReceived',
  onMessageStatusChanged: 'OnMessageStatusChanged',
  onConversationStatusChanged: 'OnConversationStatusChanged',
  onConversationAdded: 'OnConversationAdded',
  onConversationAssigneeDeleted: 'OnConversationAssigneeDeleted',
  onConversationNoteReceived: 'OnConversationNoteReceived',
  onConversationDeleted: 'OnConversationDeleted',
  onConversationAdditionalAssigneeDeleted:
    'OnConversationAdditionalAssigneeDeleted',
  onConversationAdditionalAssigneeAdded:
    'OnConversationAdditionalAssigneeAdded',
  onConversationTyping: 'OnConversationTyping',
  onConversationAssigneeChanged: 'OnConversationAssigneeChanged',
  onRemarksReceived: 'OnRemarksReceived',
  onStaffInfoUpdated: 'OnStaffInfoUpdated',
  onUserProfileFieldFormatChanged: 'OnUserProfileFieldFormatChanged',
  onUserProfileDeleted: 'OnUserProfileDeleted',
  onStripePaymentStatusUpdated: 'OnStripePaymentStatusUpdated',
  onImportCompleted: 'OnImportCompleted',
  // Dev Note: The typo is intentional from the backend
  pushNotifications: 'PushNotitions',
};

export const STANDARD_SIGNALR_INVOCATION = {
  addToGroup: 'AddToGroup',
};

export const RELIABLE_SIGNALR_INVOCATION = {
  ackMessage: 'Reliable.AckMessage',
  initSession: 'Sessions.InitSession',
  joinSession: 'Sessions.JoinSession',
};

export const RELIABLE_SIGNALR_EVENTS = {
  reliableReceiveMessage: 'Reliable.ReceiveMessage',
  associationsGroupUnassociated: 'Associations.GroupUnassociated',
  associationsGroupAssociated: 'Associations.GroupAssociated',
  associationsUserUnassociated: 'Associations.UserUnassociated',
  associationsUserAssociated: 'Associations.UserAssociated',
  leftSessionMessage: 'Sessions.LeftSessionMessage',
  joinedSession: 'Sessions.JoinedSession',
  exceptionsAlreadyJoinedSessionException:
    'Exceptions.AlreadyJoinedSessionException',
  notFoundException: 'Exceptions.SessionNotFoundException',
};

type RecursiveValues<T> = {
  [Prop in keyof T]: T[Prop] extends Record<string, any>
    ? RecursiveValues<T[Prop]>
    : T[Prop];
}[keyof T];

export type SignalREvent =
  | RecursiveValues<typeof SIGNALR_EVENTS>
  | typeof SIGNALR_INVOKE_EVENTS;

export type SignalREventName =
  | typeof STANDARD_SIGNALR_EVENTS[keyof typeof STANDARD_SIGNALR_EVENTS]
  | typeof RELIABLE_SIGNALR_EVENTS[keyof typeof RELIABLE_SIGNALR_EVENTS];
