import { useAuth0 } from '@auth0/auth0-react';
import {
  HubConnectionBuilder,
  IHttpConnectionOptions,
} from '@microsoft/signalr';
import { useEffect, useMemo } from 'react';
import { createContext } from 'react';
import { Outlet } from 'react-router-dom';
import { create } from 'zustand';
import sagaMiddleware from 'zustand-saga';

import { useCompany } from '@/api/company';
import { useMyProfile } from '@/hooks/useMyProfile';
import { base64UrlEncode } from '@/utils/url';

import rootSaga, { type State } from './sagas';

function createConnection(
  url: string,
  options: IHttpConnectionOptions = {},
  retries = [0, 1, 6000, 12000, 24000, 48000, 96000, 144000, 192000],
) {
  // create connection
  return new HubConnectionBuilder()
    .withUrl(url, options)
    .withAutomaticReconnect(retries)
    .build();
}

export const SignalRContext = createContext<State | null>(null);

export const SignalRProvider = () => {
  const { getAccessTokenSilently } = useAuth0();

  const userProfile = useMyProfile({ suspense: true });
  const { data: signalRGroupName = '' } = useCompany({
    suspense: true,
    select: ({ signalRGroupName }) => signalRGroupName,
  });
  const defaultWorkspace =
    userProfile.data?.userWorkspaces?.user_workspaces.find((w) => w.is_default);
  const serverLocation = defaultWorkspace?.server_location || '';
  const company = useCompany({ suspense: true });

  const useStore = useMemo(() => {
    return create(
      sagaMiddleware(rootSaga, (set, get, store) => ({
        subscribe: (event: string, callback: (...args: any) => void) =>
          store.putActionToSaga({
            type: 'SUBSCRIBE',
            event,
            callback,
          }),
        unsubscribe: (event: string, callback: (...args: any) => void) =>
          store.putActionToSaga({
            type: 'UNSUBSCRIBE',
            event,
            callback,
          }),
        connect: (groupName: string) =>
          store.putActionToSaga({
            type: 'CONNECT',
            groupName,
          }),
        disconnect: () => store.putActionToSaga({ type: 'DISCONNECT' }),
        standardConnection: createConnection(
          `${import.meta.env.VITE_API_BASE_URL}/chat`,
          {
            headers: {
              'X-Sleekflow-Location': serverLocation,
            },
            accessTokenFactory: getAccessTokenSilently,
          },
        ),
        reliableConnection: createConnection(
          `${
            import.meta.env.VITE_SLEEKFLOW_API_BASE_URL
          }/v1/user-event-hub/ReliableMessage?data=${base64UrlEncode(
            JSON.stringify({
              user_id: userProfile.data?.userInfo.id,
              group_ids: company.data?.id
                ? [`COMPANY_${company.data?.id}`]
                : undefined,
            }),
          )}`,
          { accessTokenFactory: getAccessTokenSilently },
        ),
      })),
    );
  }, [
    company.data?.id,
    getAccessTokenSilently,
    userProfile.data?.userInfo.id,
    serverLocation,
  ]);

  const signalr: State = useStore();

  useEffect(() => {
    signalr.connect(signalRGroupName);
    return () => {
      signalr.disconnect();
    };
  }, [signalRGroupName, signalr]);

  return (
    <SignalRContext.Provider value={signalr}>
      <Outlet />
    </SignalRContext.Provider>
  );
};
