import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link, useRouteError } from 'react-router-dom';

import NotFoundErrorElement from '@/components/NotFoundErrorElement';
import ServerErrorElement from '@/components/ServerErrorElement';
import { ROUTES } from '@/constants/navigation';
import { isNotFoundError } from '@/errors/NotFoundError';
import { isServerError } from '@/errors/ServerError';
import useBackToURL from '@/hooks/useBackToURL';

const ContactsIdError = () => {
  const error = useRouteError();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const backToURL = useBackToURL({ fallback: ROUTES.contacts });
  if (isNotFoundError(error)) {
    return (
      <NotFoundErrorElement
        mode="component"
        error={error}
        title={error.title}
        description={t('contacts-detail.error.contact-not-found-description', {
          defaultValue: 'The contact you are looking for does not exist.',
        })}
        actions={
          <Button
            variant="contained"
            to={backToURL}
            component={Link}
            onClick={() => {
              queryClient.resetQueries({
                predicate: (query) => !!query.state.error,
              });
            }}
          >
            {t('contacts-detail.error.contact-not-found-back-button', {
              defaultValue: 'Back',
            })}
          </Button>
        }
      />
    );
  }

  if (isServerError(error) || error instanceof Error) {
    return (
      <ServerErrorElement
        mode="component"
        title={isServerError(error) ? error.title : ''}
        error={error}
        actions={
          <Button
            to={backToURL}
            component={Link}
            variant="contained"
            onClick={() => {
              queryClient.resetQueries({
                predicate: (query) => !!query.state.error,
              });
            }}
          >
            {t('contacts-detail.error-server-error-back-button', {
              defaultValue: 'Back',
            })}
          </Button>
        }
      />
    );
  }

  return (
    <ServerErrorElement
      actions={
        <Button
          component={Link}
          variant="contained"
          to={backToURL}
          onClick={() => {
            queryClient.resetQueries({
              predicate: (query) => !!query.state.error,
            });
          }}
        >
          {t('contacts-detail.error-server-error-back-button', {
            defaultValue: 'Back',
          })}
        </Button>
      }
    />
  );
};

export default ContactsIdError;
