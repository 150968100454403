import dayjs from 'dayjs';

import { useCompany, useCompanyUsageQuery } from '@/api/company';
import {
  PLAN_NAMES_REGEX,
  SUBSCRIPTION_TIER,
  type SubscriptionPlanName,
} from '@/constants/subscription-plans';
import { useMyProfile } from '@/hooks/useMyProfile';

/* testing creating a global access rule guard
 * current thoughts are to split it into 2 hooks and combine into a super hook.
 * - useCompanyAccessRuleGuard hook are things that are limited to whole company (subscription plan, add ons, channels etc.)
 * - useUserAccessRuleGuard hook are specific to the person (role type, permissions, phone number masking etc.)
 * - where possible, don't use boolean types if the option has multiple possible values.
 *   - eg. for user role instead of returning things like isStaff, isAdmin, return the actual role
 *     to make it more extensible and minimise the number of things need to expose.
 * */

const useCompanyAccessRuleGuard = ({ suspense }: { suspense?: boolean }) => {
  const { data: companyUsage } = useCompanyUsageQuery({ suspense });
  const company = useCompany({
    enabled: !!companyUsage,
    suspense,
    select: (data) => {
      const activeBillRecordsWithoutAddOns = data.billRecords
        .filter((record) => {
          return (
            record.subscriptionPlan &&
            [SUBSCRIPTION_TIER.addOn, SUBSCRIPTION_TIER.agent].indexOf(
              record.subscriptionPlan.subscriptionTier,
            ) === -1 &&
            dayjs.utc(record.periodEnd).isAfter(dayjs.utc())
          );
        })
        .sort((a, b) => (dayjs(a.periodEnd).isAfter(b.periodEnd) ? 1 : -1))
        .sort((a, b) => {
          return (
            a.subscriptionPlan.subscriptionTier -
            b.subscriptionPlan.subscriptionTier
          );
        });

      const lastBillRecord =
        activeBillRecordsWithoutAddOns.length > 0
          ? activeBillRecordsWithoutAddOns[
              activeBillRecordsWithoutAddOns.length - 1
            ]
          : undefined;

      return {
        currentPlan: {
          billRecord: lastBillRecord,
          transformedPlanDetails: transformPlanDetails(
            lastBillRecord?.subscriptionPlan.id,
          ),
        },
        maximumAgents: data.maximumAgents,
        currentAgents: data.currentAgents,
        maximumWhatsappInstance: data.maximumWhatsappInstance,
        currentNumberOfCloudAPIChannels: data.whatsappCloudApiConfigs.length,
        maximumAutomations: data.maximumAutomations,
        maximumNumberOfChannel: data.maximumNumberOfChannel,
        remainingWhatappChannelQuota:
          data.maximumWhatsappInstance - data.whatsappCloudApiConfigs.length,
        remainingAgentQuota: data.maximumAgents - data.currentAgents,
        addOnPlanStatus: data.addonStatus,
        maximumContacts: companyUsage?.maximumContacts,
        totalContacts: companyUsage?.totalContacts,
      };
    },
  });

  return company;
};

const transformPlanDetails = (planName: string | undefined) => {
  if (!planName) {
    return {
      planName: 'startup',
      billingPeriod: null,
    };
  }

  let transformedPlanName: SubscriptionPlanName | null = null;
  let planPeriod: 'monthly' | 'yearly' | null = null;

  Object.values(PLAN_NAMES_REGEX).forEach((value) => {
    if (value.regex.test(planName)) {
      transformedPlanName = value.name;
      if ('period' in value) {
        planPeriod = value.period;
      }
    }
  });

  return {
    planName: transformedPlanName as SubscriptionPlanName | null,
    billingPeriod: planPeriod as 'monthly' | 'yearly' | null,
  };
};

const useAccessRuleGuard = (options?: { suspense: boolean }) => {
  const companyAccessRuleGuard = useCompanyAccessRuleGuard({
    suspense: options?.suspense,
  });
  const userAccessRuleGuard = useMyProfile({ suspense: options?.suspense });

  return {
    user: userAccessRuleGuard,
    company: companyAccessRuleGuard,
  };
};

export default useAccessRuleGuard;
