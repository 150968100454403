class FlowBuilderError extends Error {
  title = '';
  description = '';
  status = 404;
  constructor({
    title,
    description,
    status = 404,
  }: {
    title?: string;
    description?: string;
    status?: number;
  }) {
    super(title ?? 'Flow builder error');
    title && (this.title = title);
    description && (this.description = description);
    status && (this.status = status);
  }
}

export const isFlowBuilderError = (
  error: unknown,
): error is FlowBuilderError => {
  return error instanceof FlowBuilderError;
};

export default FlowBuilderError;
