import { SubscriptionPlan } from '@/api/types';

export enum SubscriptionTier {
  Free = 0,
  Pro = 1,
  Premium = 2,
  Enterprise = 3,
  AddOn = 4,
  Agent = 5,
}

export const ADDON_PLAN_TIERS = [
  SubscriptionTier.AddOn,
  SubscriptionTier.Agent,
];

export function isFreeOrFreemiumPlan(plan: SubscriptionPlan) {
  return plan.subscriptionTier === SubscriptionTier.Free;
}

export function isProPlan(plan: SubscriptionPlan) {
  return plan.subscriptionTier === SubscriptionTier.Pro;
}

export function isEnterprisePlan(plan: SubscriptionPlan) {
  return plan.subscriptionTier === SubscriptionTier.Enterprise;
}

export function isPremiumPlan(plan: SubscriptionPlan) {
  return plan.subscriptionTier === SubscriptionTier.Premium;
}

export function isYearlyPlan(plan: SubscriptionPlan) {
  return /yearly/i.test(plan.id);
}
