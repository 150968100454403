import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';

import { auditHubKeys } from '@/api/auditHub';
import { useAxios } from '@/api/axiosClient';
import {
  INBOX_DEFAULT_CONVERSATION_ORDER,
  INBOX_LIMIT_PER_PAGE,
} from '@/pages/Inbox/constants';
import { MessageType, Thread } from '@/pages/Inbox/types';

import { companyKeys } from './company';
import type {
  Assignee,
  ChannelType,
  Conversation,
  ConversationMessage,
  ConversationStatus,
  Team,
  Whatsapp360DialogExtendedMessagePayloadType,
  WhatsappCloudExtendedMessagePayloadType,
  WhatsappTwilioContentExtendedMessagePayloadType,
} from './types';
import { userProfileKeys } from './userProfile';

export type ConversationMessageFilters = {
  conversationId?: string;
  offset?: number;
  limit?: number;
  order?: 'asc' | 'desc';
  afterTimestamp?: number;
  beforeTimestamp?: number;
  channel?: ChannelType;
  channelIds?: string | number;
  IsFromUser?: boolean;
  IsFromImport?: boolean;
  isGetFileOnly?: boolean;
} & { timestamp?: number };

interface ConversationSummaryFilters {
  status?: ConversationStatus;
  afterUpdatedAt?: string;
  afterModifiedAt?: string;
  channelIds?: Record<string, string>[];
  tags?: string;
  teamId?: string;
  isTeamUnassigned?: boolean;
  isAssigned?: boolean;
  isUnread?: boolean;
  channel?: string[];
  behaviourVersion?: '1' | '2';
}

export interface ConversationAssignedToFilters extends ConversationFilters {
  offset?: number;
  limit?: number;
  orderBy?: 'asc' | 'desc';
}

export interface ConversationFilters extends ConversationSummaryFilters {
  assignedTo:
    | 'all'
    | 'unassigned'
    | 'mentioned'
    | 'team'
    | Omit<
        string,
        'all' | 'unassigned' | 'mentioned' | 'collaborator' | 'team'
      >;
}

export interface ConversationsParams {
  status: string;
  assignedTo?: string;
  isTeamUnassigned?: boolean;
  teamId?: string;
  isAssigned?: boolean;
  orderBy?: 'asc' | 'desc';
  isStaffFetchingAll: boolean;
}

export const conversationKeys = createQueryKeys('conversation', {
  conversationMessageByMessageUniqueID: (params: { messageID: string }) => [
    { ...params },
  ],
  addLabels: () => ['conversations', 'setHashtagLabels'],
  conversationWithFiltering: (params: ConversationAssignedToFilters) => [
    { ...params },
  ],
  threadCountSummary: (params: ConversationFilters) => [{ ...params }],
  message: (
    params: ConversationMessageFilters,
    options: { type?: 'query' | 'infinite' } = {},
  ) => [params, options],
  unreadSummary: null,
  getConversationById: ({ conversationId }: { conversationId: string }) => [
    { conversationId },
  ],
  getUrlByFileId: ({ fileId }: { fileId: string }) => [{ fileId }],
  assigneeMenuItems: null,
  conversations: (params: ConversationsParams) => [{ ...params }],
  summary: (params) => [{ ...params }],
  conversationTopControls: ({
    threadId,
    channelId,
  }: {
    threadId: string;
    channelId?: string | number;
  }) => [threadId, { channelId }],
  conversationSearch: (params) => [{ ...params }],
  conversationSearchCount: (params) => [{ ...params }],
});

export interface ConversationMessageMutationArgs {
  channelIdentityId?: string;
  conversationId: string;
  messageGroupName?: string;
  messageContent?: string;
  messageType: MessageType;
  receiverDeviceUUID?: string;
  facebookReceiverId?: string;
  webClientSenderId?: string;
  receiverId?: string;
  channel: ChannelType;
  emailFrom?: string;
  emailTo?: string;
  emailCC?: string;
  subject?: string;
  assigneeId?: string;
  weChatReceiverOpenId?: string;
  messageChecksum?: string;
  quotedMsgId?: string;
  fileURLs?: string[];
  quickReplyId?: string | number;
  scheduleSentAt?: string;
  localTimestamp?: number;
  channelId?: number;
  whatsapp360dialogReceiverId?: string;
  whatsappCloudApiReceiverId?: string;
  messageTag?: string;
  paymentIntentId?: string;
  extendedMessagePayload?:
    | WhatsappCloudExtendedMessagePayloadType
    | WhatsappTwilioContentExtendedMessagePayloadType;
  whatsapp360DialogExtendedMessagePayload?: Whatsapp360DialogExtendedMessagePayloadType;
  files: Array<File>;
}

// TODO: remove duplicated interface when the refactor the text box module, because don't to break anything
export interface MessageSendPayload {
  conversationId: string;
  messageGroupName?: string;
  messageContent?: string;
  messageType?: string;
  receiverDeviceUUID?: string;
  facebookReceiverId?: string;
  webClientSenderId?: string;
  receiverId?: string;
  channel?: string;
  emailFrom?: string;
  emailTo?: string;
  emailCC?: string;
  subject?: string;
  assigneeId?: string;
  weChatReceiverOpenId?: string;
  messageChecksum?: string;
  quotedMsgId?: string;
  fileURLs?: string[];
  quickReplyId?: string;
  scheduleSentAt?: string;
  localTimestamp?: number;
  channelId?: number;
  whatsapp360dialogReceiverId?: string;
  messageTag?: string;
  paymentIntentId?: string;
  Whatsapp360DialogExtendedMessagePayload?: Whatsapp360DialogExtendedMessagePayloadType;
  files: Array<File>;
  whatsappCloudApiReceiverId?: string;
}

interface ConversationTypingMutationArgs {
  conversationId: string;
  staffId: string;
}

interface ConversationTypingResponseType {
  code: number;
  message: string;
  errorId: string;
  timestamp: string;
}

export const useConversationsWithFiltering = <T = Conversation[]>({
  params,
  select,
  enabled,
  getNextPageParam,
}: {
  params: ConversationAssignedToFilters;
  select?: (data: InfiniteData<T>) => InfiniteData<Thread>;
  enabled?: boolean;
  getNextPageParam?: (lastPage: T, allPages: T[]) => unknown | undefined;
}) => {
  const url = `/v2/Conversations/${params.assignedTo}`;
  const axiosClient = useAxios();
  return useInfiniteQuery({
    queryKey: conversationKeys.conversationWithFiltering(params),
    queryFn: async ({ signal, pageParam }) => {
      const response = await axiosClient.get(url, {
        signal,
        params: { ...params, ...pageParam },
      });
      return response.data;
    },
    meta: {
      url,
      description: 'Fetches conversations based on variety of params',
    },
    select,
    getNextPageParam,
    enabled,
  });
};

export function useConversationMessage<T = ConversationMessage[]>({
  select,
  params: {
    conversationId,
    offset = 0,
    limit = 10,
    order = 'desc',
    ...restParams
  },
  enabled,
  suspense,
}: {
  select?: (data: ConversationMessage[]) => T;
  suspense?: boolean;
  params: ConversationMessageFilters;
  enabled?: boolean;
}): UseQueryResult<T, unknown> {
  const url = `/Conversation/Message/${conversationId}`;
  const axiosClient = useAxios();
  return useQuery({
    queryKey: conversationKeys.message(
      {
        conversationId: conversationId,
        offset,
        limit,
        order,
        ...restParams,
      },
      {
        type: 'query',
      },
    ),
    queryFn: async () => {
      const response = await axiosClient.get<ConversationMessage[]>(url, {
        params: { offset, limit, order, ...restParams },
      });

      return response.data;
    },
    suspense,
    select,
    enabled,
    meta: {
      url,
      description: 'Get all conversation`s msg',
    },
  });
}

export function useConversationMessageByMessageUniqueIDQuery<
  T = ConversationMessage[],
>(
  {
    messageID,
  }: {
    messageID: string;
  },
  options?: { enabled?: boolean; select?: (data: ConversationMessage[]) => T },
) {
  const axiosClient = useAxios();
  return useQuery({
    queryKey: conversationKeys.conversationMessageByMessageUniqueID({
      messageID: messageID,
    }),
    queryFn: async ({ signal }) => {
      const response = await axiosClient.get<ConversationMessage[]>(
        `/Conversation/Message/MessageUniqueId/${messageID}`,
        {
          signal,
        },
      );
      return response.data;
    },
    useErrorBoundary: false,
    select: options?.select,
    enabled: options?.enabled,
  });
}

export function useInfiniteConversationMessageId<
  T = {
    data: ConversationMessage[];
    pageParam: any;
  },
>(
  {
    conversationId,
    limit = INBOX_LIMIT_PER_PAGE,
    order = INBOX_DEFAULT_CONVERSATION_ORDER,
    timestamp,
    ...restParams
  }: Omit<ConversationMessageFilters, 'beforeTimestamp' | 'afterTimestamp'>,
  options?: {
    cacheTime?: number;
    staleTime?: number;
    select?: (
      data: InfiniteData<{
        data: ConversationMessage[];
        pageParam: any;
      }>,
    ) => InfiniteData<T>;
    suspense?: boolean;
    enabled?: boolean;
  },
) {
  const axiosClient = useAxios();
  const url = `/Conversation/Message/${conversationId}`;
  return useInfiniteQuery(
    conversationKeys.message(
      {
        conversationId,
        limit,
        order,
        timestamp,
        ...restParams,
      },
      {
        type: 'infinite',
      },
    ),
    {
      queryFn: async ({
        signal,
        pageParam = {
          initialTimestamp: timestamp,
          nextTimestamp: undefined,
          prevTimestamp: undefined,
        },
      }) => {
        const castedPageParam = pageParam as {
          initialTimestamp?: number;
          nextTimestamp?: number;
          prevTimestamp?: number;
        };

        if (castedPageParam.initialTimestamp) {
          const prevTimestampResponse = await axiosClient.get<
            ConversationMessage[]
          >(url, {
            signal,
            params: {
              limit,
              order,
              beforeTimestamp: castedPageParam.initialTimestamp,
              ...restParams,
            },
          });

          const nextTimestampResponse = await axiosClient.get<
            ConversationMessage[]
          >(url, {
            signal,
            params: {
              limit,
              order,
              afterTimestamp: castedPageParam.initialTimestamp,
              ...restParams,
            },
          });

          return {
            data: [
              ...prevTimestampResponse.data,
              ...nextTimestampResponse.data,
            ],
            pageParam,
          };
        }

        const response = await axiosClient.get<ConversationMessage[]>(url, {
          signal,
          params: {
            limit,
            order,
            afterTimestamp: castedPageParam.nextTimestamp,
            beforeTimestamp:
              castedPageParam.prevTimestamp ?? dayjs().add(100, 'year').unix(),
            ...restParams,
          },
        });

        return {
          data: response.data,
          pageParam,
        };
      },
      cacheTime: options?.cacheTime,
      staleTime: options?.staleTime,
      select: options?.select,
      enabled: options?.enabled,
      getPreviousPageParam: (firstPage) => {
        if (!firstPage.data || firstPage.data.length === 0) {
          return undefined;
        }

        const prevTimestamp =
          firstPage.data[firstPage.data.length - 1].timestamp;

        if (prevTimestamp === firstPage.pageParam?.prevTimestamp) {
          return undefined;
        }

        return {
          initialTimestamp: undefined,
          prevTimestamp,
        };
      },
      getNextPageParam: (lastPage) => {
        if (!lastPage.data || lastPage.data.length === 0) {
          return undefined;
        }

        const nextTimestamp = lastPage.data[lastPage.data.length - 1].timestamp;

        if (nextTimestamp === lastPage.pageParam?.nextTimestamp) {
          return undefined;
        }

        return {
          initialTimestamp: undefined,
          nextTimestamp,
        };
      },
    },
  );
}

export const useCreateConversationNote = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: ConversationMessageMutationArgs) => void;
  onSuccess?: (res: ConversationMessageMutationArgs) => void;
  onError?: (
    error: unknown,
    variables: ConversationMessageMutationArgs,
    context: unknown | undefined,
  ) => void;
}) => {
  const axiosClient = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const url = `/Conversation/Note/${data.conversationId}`;
      const response = await axiosClient.post<ConversationMessageMutationArgs>(
        url,
        data,
      );

      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(conversationKeys.message._def);
    },
  });
};

export const useConversationTypingMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: ConversationTypingMutationArgs) => void;
  onSuccess?: (
    data: ConversationTypingResponseType,
    variables: ConversationTypingMutationArgs,
  ) => void;
  onError?: (
    error: unknown,
    variables: ConversationTypingMutationArgs,
    context: unknown | undefined,
  ) => void;
} = {}) => {
  const url = '/conversation/typing';
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const response = await axiosClient.post<
        ConversationTypingResponseType,
        AxiosResponse<ConversationTypingResponseType>,
        ConversationTypingMutationArgs
      >(url, data);

      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
    meta: {
      url,
      description: 'Send typing signal to server',
    },
  });
};

export function useConversation<T = Conversation>({
  conversationId,
  select,
  enabled,
  keepPreviousData,
}: {
  conversationId: string;
  select?: (data: Conversation) => T;
  enabled?: boolean;
  keepPreviousData?: boolean;
}) {
  const url = `/Conversation/${conversationId}`;
  const axiosClient = useAxios();
  return useQuery({
    enabled,
    queryKey: conversationKeys.getConversationById({ conversationId }),
    queryFn: async ({ signal }) => {
      const response = await axiosClient.get<Conversation>(url, {
        signal,
      });
      return response.data;
    },
    select,
    keepPreviousData,
    meta: {
      url,
      description: 'Get conversation detail',
    },
  });
}

export interface AssignConversationMutationArgs {
  staffId?: string;
  assignmentType?: 'SpecificPerson' | 'SpecificGroup' | 'Unassigned';
  teamId?: string;
  teamAssignmentType?: 'QueueBased' | 'Unassigned';
}

interface AssignConversationMutationResponse {
  conversationId: string;
  companyId: string;
  status: string;
  assignee: Assignee;
  assignedTeam: Team;
  additionalAssignees: { assignee: Assignee }[];
  updatedTime: string;
}

/**
 * Params for:
 * Unassign: { assignmentType: 'Unassigned' }
 * Assign to person: { assignmentType: 'SpecificPerson', staffId: '123' }
 * Assign to team: { assignmentType: 'SpecificGroup', teamId: '123' }
 * Assign to team queue: { assignmentType: 'SpecificGroup', teamId: '123', teamAssignmentType: 'QueueBased' }
 * Assign to team waiting list (first responder): { assignmentType: 'SpecificGroup', teamId: '123', teamAssignmentType: 'unassigned' }
 */
export function useAssignConversationMutation({
  conversationId,
}: {
  conversationId: string;
}) {
  const url = `/v2/conversation/assignee/${conversationId}`;
  const axiosClient = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: AssignConversationMutationArgs) => {
      const response =
        await axiosClient.post<AssignConversationMutationResponse>(
          url,
          variables,
        );
      return response.data;
    },
    onSuccess: async (data) => {
      const conversationQueryKey = conversationKeys.getConversationById({
        conversationId,
      });
      queryClient.invalidateQueries(conversationKeys.threadCountSummary._def);
      queryClient.invalidateQueries(
        conversationKeys.conversationTopControls._def,
      );
      queryClient.invalidateQueries(userProfileKeys.getUserProfile._def);
      queryClient.invalidateQueries(userProfileKeys.getUserProfileDetail._def);
      queryClient.invalidateQueries(conversationKeys.unreadSummary);

      // TODO: Invalidation won't help at time since BE has cache in place
      await queryClient.cancelQueries(conversationQueryKey);
      queryClient.setQueryData<Conversation>(conversationQueryKey, (prev) => {
        if (prev) {
          return {
            ...prev,
            assignee: data.assignee,
            assignedTeam: data.assignedTeam,
          };
        }
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(conversationKeys.assigneeMenuItems);
      // TODO: remove this when BE cache is removed
      setTimeout(() => {
        queryClient.invalidateQueries(conversationKeys.conversations._def);
      }, 5000);
    },
  });
}

interface ConversationCollaboratorMutationArgs {
  additionalAssigneeIds: string[];
}

export function useConversationCollaboratorMutation({
  conversationId,
}: {
  conversationId: string;
}) {
  const url = `/v2/conversation/collaborator/${conversationId}`;
  const axiosClient = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      additionalAssigneeIds,
    }: ConversationCollaboratorMutationArgs) => {
      const response =
        await axiosClient.post<AssignConversationMutationResponse>(url, {
          additionalAssigneeIds,
        });
      return response.data;
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(conversationKeys.threadCountSummary._def);
      // TODO: Invalidation won't help at time since BE has cache in place
      const conversationQueryKey = conversationKeys.getConversationById({
        conversationId,
      });
      await queryClient.cancelQueries(conversationQueryKey);
      queryClient.setQueryData<Conversation>(conversationQueryKey, (prev) => {
        if (prev) {
          return {
            ...prev,
            additionalAssignees: data.additionalAssignees,
          };
        }
      });
    },
  });
}

interface ConversationStatusMutationArgs {
  status: ConversationStatus;
  snoozeOptions?: number;
  snoozeUntil?: string;
}

interface ConversationStatusMutationResponse {
  conversationId: string;
  status: ConversationStatus;
  snoozeUntil?: string;
}

export function useConversationStatusMutation({
  conversationId,
  onSuccess,
}: {
  conversationId: string;
  onSuccess?: (data: ConversationStatusMutationResponse) => void;
  onError?: (error: AxiosError) => void;
}) {
  const url = `/Conversation/Status/${conversationId}`;
  const axiosClient = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables: ConversationStatusMutationArgs) => {
      const response =
        await axiosClient.post<ConversationStatusMutationResponse>(url, {
          ...variables,
        });
      return response.data;
    },
    onSuccess: async (data) => {
      // TODO: Invalidation won't help at time since BE has cache in place
      const conversationQueryKey = conversationKeys.getConversationById({
        conversationId,
      });
      await queryClient.cancelQueries(conversationQueryKey);
      queryClient.setQueryData<Conversation>(conversationQueryKey, (prev) => {
        if (prev) {
          return {
            ...prev,
            status: data.status,
          };
        }
      });
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });
}

interface StarConversationMutationArgs {
  star: boolean;
}

interface StarConversationResponse {
  conversationId: string;
}

export function useStarConversationMutation({
  conversationId,
}: {
  conversationId: string;
}) {
  const url = `/conversation/star/${conversationId}`;
  const axiosClient = useAxios();
  const queryClient = useQueryClient();

  const conversationQueryKey = conversationKeys.getConversationById({
    conversationId,
  });

  return useMutation({
    mutationFn: async (variables: StarConversationMutationArgs) => {
      const response = await axiosClient.post<StarConversationResponse>(url, {
        ...variables,
      });
      return response.data;
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries(conversationQueryKey);
      const previousConversation =
        queryClient.getQueryData<Conversation>(conversationQueryKey);

      queryClient.setQueryData<Conversation>(conversationQueryKey, (prev) => {
        if (prev) {
          return {
            ...prev,
            isStarred: data.star,
          };
        }
      });
      return { previousConversation };
    },
    onError: (_err, _data, context) => {
      const castContext = context as { previousConversation: Conversation };
      if (castContext) {
        queryClient.setQueryData<Conversation>(
          conversationQueryKey,
          castContext.previousConversation,
        );
      }
    },
  });
}

// TODO: Refactor, BE should only need the id for delete
export interface ConversationTagsMutationArgs {
  id: string;
  hashtag: string;
  hashTagColor: string;
  hashTagType: string;
}

// Include Set Tags (Update Tags), Add Tags and Delete Tags in one hook
export function useConversationTagsMutation({
  conversationId,
}: {
  conversationId: string;
}) {
  const axiosClient = useAxios();
  const queryClient = useQueryClient();

  async function onSuccess(data: Conversation) {
    const conversationQueryKey = conversationKeys.getConversationById({
      conversationId,
    });
    await queryClient.cancelQueries(conversationQueryKey);
    const previousConversation =
      queryClient.getQueryData<Conversation>(conversationQueryKey);

    if (previousConversation) {
      // Invalidate the user profile detail to update the tags
      await queryClient.invalidateQueries(
        userProfileKeys.getUserProfileDetail({
          id: previousConversation.userProfile.id,
        }),
      );
    }

    // Invalidate all conversations to update the tags
    queryClient.invalidateQueries(
      auditHubKeys.getAuditLogsGetUserProfileAuditLogs._def,
    );
    await queryClient.invalidateQueries(companyKeys.getCompanyTags._def);
    queryClient.invalidateQueries(conversationKeys.conversations._def);
    // Update the conversation detail
    queryClient.setQueryData<Conversation>(conversationQueryKey, (prev) => {
      if (prev) {
        return {
          ...prev,
          conversationHashtags: data.conversationHashtags,
        };
      }
    });
  }

  const remove = useMutation({
    mutationFn: async (variables: Partial<ConversationTagsMutationArgs>[]) => {
      const response = await axiosClient.post<Conversation>(
        `/conversation/tags/remove/${conversationId}`,
        variables,
      );
      return response.data;
    },
    onSuccess,
  });

  const add = useMutation({
    mutationFn: async (variables: Partial<ConversationTagsMutationArgs>[]) => {
      const response = await axiosClient.post<Conversation>(
        `/conversation/tags/add/${conversationId}`,
        variables,
      );
      return response.data;
    },
    onSuccess,
  });

  const set = useMutation({
    mutationKey: conversationKeys.addLabels(),
    mutationFn: async (variables: Partial<ConversationTagsMutationArgs>[]) => {
      const response = await axiosClient.post<Conversation>(
        `/conversation/tags/${conversationId}`,
        variables,
      );
      return response.data;
    },
    onSuccess,
  });

  return {
    add,
    remove,
    set,
  };
}

export function useDeleteMessageMutation(threadId: string) {
  const url = 'Message/Schedule/Delete';
  const axiosClient = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: { messageIds: number[] }) => {
      return await axiosClient.post(url, variables);
    },
    onMutate: async ({ messageIds }) => {
      await queryClient.cancelQueries({
        queryKey: conversationKeys.message({
          conversationId: threadId,
        }),
      });
      queryClient.setQueriesData<
        InfiniteData<{
          data: ConversationMessage[];
          pageParam: any;
        }>
      >(
        conversationKeys.message({
          conversationId: threadId,
        }),
        (old) => {
          if (!old || !old.pages) return old;
          return {
            ...old,
            pages: old.pages.map((page) => {
              return {
                ...page,
                data: page.data.filter((message) => {
                  console.log(message.id);
                  return Number(message.id) !== messageIds[0];
                }),
              };
            }),
          };
        },
      );
    },

    onSettled: async () => {
      queryClient.invalidateQueries({ queryKey: ['messages', threadId] });
    },
  });
}

export interface PinConversationParamType {
  isBookmarked: boolean;
}

export interface PinConversationResponseType {
  conversationId: string;
  companyId: string;
  status: string;
  isBookmarked: boolean;
  assignee: Assignee;
  updatedTime: string;
}

export function usePinConversation({
  conversationId,
  onSuccess,
  onError,
}: {
  conversationId: string;
  onSuccess: (
    _data: PinConversationResponseType,
    params: PinConversationParamType,
  ) => void;
  onError: () => void;
}) {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (param) => {
      const url = `/Conversation/Bookmark/${conversationId}?bookmark=${param.isBookmarked}`;
      const response = await axiosClient.post<
        PinConversationResponseType,
        AxiosResponse<PinConversationResponseType>,
        PinConversationParamType
      >(url);
      return response.data;
    },
    onSuccess,
    onError,
  });
}
