import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRouteWithLocale } from '@/hooks/useRouteWithLocale/useRouteWithLocale';
import { useSignalREffect, STANDARD_SIGNALR_EVENTS } from '@/signalr';

interface PushNotificationEvent {
  event: string;
  title: string;
  body: string;
  badge: number;
  conversationId: string;
  profileName: string;
  companyId: string;
  assigneeId?: string;
}

export default function PushNotification() {
  const audioRef = useRef<HTMLAudioElement>(null);
  const routeTo = useRouteWithLocale();
  const navigate = useNavigate();

  function playSound() {
    if (audioRef.current) {
      audioRef.current.muted = false;
      audioRef.current.volume = 1;
      audioRef.current.play().catch(() => {
        // Chrome throws error if audio is played without user interaction
        // https://developer.chrome.com/blog/autoplay/
      });
    }
  }

  function notifyNewMessage(data: PushNotificationEvent) {
    const notification = new Notification(data.title, {
      badge: String(data.badge),
      body: data.body,
      icon: `${window.location.origin}/favicon/android-chrome-192x192.png`,
    });

    if (data.conversationId) {
      notification.addEventListener('click', () => {
        navigate(
          routeTo({
            pathname: '/inbox',
            search: `?threadId=${data.conversationId}`,
          }),
        );
      });
    }

    playSound();
  }

  useSignalREffect(
    STANDARD_SIGNALR_EVENTS.pushNotifications,
    (data: PushNotificationEvent) => {
      if (data.event === 'NewMessage') {
        if (!('Notification' in window)) {
          return;
        }

        if (Notification.permission === 'granted') {
          notifyNewMessage(data);
        } else if (Notification.permission !== 'denied') {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              notifyNewMessage(data);
            }
          });
        }
      }
    },
  );

  return (
    <audio ref={audioRef} hidden muted>
      <source
        src="https://s3-ap-southeast-1.amazonaws.com/app.sleekflow.io/static/audio/chat_sound.mp3"
        type="audio/mp3"
      />
      <track kind="captions" />
    </audio>
  );
}
