import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { eventChannel } from 'redux-saga';
import {
  call,
  take,
  select,
  fork,
  takeLeading,
  spawn,
  delay,
} from 'redux-saga/effects';

import {
  type SignalREventName,
  STANDARD_SIGNALR_EVENTS,
  STANDARD_SIGNALR_INVOCATION,
  RELIABLE_SIGNALR_EVENTS,
  RELIABLE_SIGNALR_INVOCATION,
} from './constants';

export interface Action {
  type: string;
  event: string;
  callback: (...args: any) => void;
}

export interface State {
  subscribe: (event: string, callback: (...args: any) => void) => void;
  unsubscribe: (event: string, callback: (...args: any) => void) => void;
  connect: (groupName: string) => void;
  standardConnection: HubConnection;
  reliableConnection: HubConnection;
  disconnect: () => void;
}

function createReliableChannel(event: string, state: State) {
  return eventChannel((emit) => {
    state.reliableConnection.on(event, (...args: any) => emit(args));
    return () => state.reliableConnection.off(event);
  });
}

const standardEvent = (event: SignalREventName) => {
  return Object.values(STANDARD_SIGNALR_EVENTS).includes(event);
};

function* connectWithAttemptRetrySaga(
  connection: HubConnection,
  maxRetries = 10,
  baseDelay = 1000 * 10, // 10 Seconds,
) {
  // Start connection with attempt retry
  // Connection could be fail due to various reasons, so here is a retry with exponential backoff
  for (let i = 0; i < maxRetries; i++) {
    try {
      yield call(() => connection.start());
      return;
    } catch (error) {
      const delayDuration = baseDelay * Math.pow(2, i); // Exponential backoff
      console.warn(
        `Retry attempt ${i}: waiting for ${
          delayDuration / 1000
        } seconds before retrying`,
        error,
      );
      yield delay(delayDuration);
    }
  }
}

function* rootSaga() {
  yield fork(function* connectSaga() {
    let sessionId = '';
    while (true) {
      const { groupName } = yield take('CONNECT');
      const state: State = yield select();

      // Standard connection with attempt retry
      yield connectWithAttemptRetrySaga(state.standardConnection);
      yield spawn(() => {
        return state.standardConnection.invoke(
          STANDARD_SIGNALR_INVOCATION.addToGroup,
          groupName,
        );
      });

      // Reliable connection with attempt retry
      yield connectWithAttemptRetrySaga(state.reliableConnection);
      yield spawn(() =>
        state.reliableConnection.invoke(
          RELIABLE_SIGNALR_INVOCATION.initSession,
        ),
      );

      // Wait for session id and store into the variable
      [sessionId] = yield take(
        createReliableChannel(RELIABLE_SIGNALR_EVENTS.joinedSession, state),
      );

      // Hanndle message ack mechanism
      yield fork(function* messageAckSaga() {
        while (true) {
          const [_, __, str]: string = yield take(
            createReliableChannel(
              RELIABLE_SIGNALR_EVENTS.reliableReceiveMessage,
              state,
            ),
          );
          // Acknowledge the backend when recived message
          const m: Record<'message_id', string> = JSON.parse(str);
          yield call(() =>
            state.reliableConnection.invoke(
              RELIABLE_SIGNALR_INVOCATION.ackMessage,
              m.message_id,
            ),
          );
        }
      });

      // Handle reconnection
      yield fork(function* reconnectSaga() {
        while (true) {
          yield take(
            eventChannel((emit) => {
              state.reliableConnection.onreconnected(() => emit('reconnected'));
              return () => state.reliableConnection.off('onreconnected');
            }),
          );
          // Rejoin / Reinit session when reconnected
          if (sessionId) {
            yield call(() =>
              state.reliableConnection.invoke(
                RELIABLE_SIGNALR_INVOCATION.joinSession,
                sessionId,
              ),
            );
          } else {
            yield call(() =>
              state.reliableConnection.invoke(
                RELIABLE_SIGNALR_INVOCATION.initSession,
              ),
            );
          }
        }
      });

      // Handle sessions
      yield fork(function* notFoundExceptionSaga() {
        while (true) {
          yield take(
            createReliableChannel(
              RELIABLE_SIGNALR_EVENTS.notFoundException,
              state,
            ),
          );
          yield call(() => state.reliableConnection.invoke('Sessions.Init'));
        }
      });

      yield fork(function* leftSessionSaga() {
        while (true) {
          const newSessionId = yield take(
            createReliableChannel(
              RELIABLE_SIGNALR_EVENTS.leftSessionMessage,
              state,
            ),
          );
          sessionId = newSessionId === sessionId ? newSessionId : '';
        }
      });
    }
  });

  yield fork(function* disconnectSaga() {
    while (true) {
      yield take('DISCONNECT');
      const signalr: State = yield select();
      [signalr.standardConnection, signalr.reliableConnection].forEach(
        function* (connection) {
          if (connection?.state === HubConnectionState.Connected)
            yield call(() => connection.stop());
        },
      );
    }
  });

  yield fork(function* subscribeSaga() {
    yield takeLeading<Action>(
      'SUBSCRIBE',
      function* ({
        event,
        callback,
      }: {
        event: string;
        callback: (...args: any) => void;
      }) {
        const signalr: State = yield select();
        const connection = standardEvent(event)
          ? signalr.standardConnection
          : signalr.reliableConnection;
        yield call(() => connection.on(event, callback));
      },
    );
  });

  yield fork(function* subscribeSaga() {
    yield takeLeading<Action>(
      'UNSUBSCRIBE',
      function* ({
        event,
        callback,
      }: {
        event: string;
        callback: (...args: any) => void;
      }) {
        const signalr: State = yield select();
        const connection = standardEvent(event)
          ? signalr.standardConnection
          : signalr.reliableConnection;
        yield call(() => connection.off(event, callback));
      },
    );
  });
}

export default rootSaga;
