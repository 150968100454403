import { Button, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import CommonErrorStateLayout from '@/components/CommonErrorStateLayout';
import Icon from '@/components/Icon';
import { ScrollArea } from '@/components/ScrollArea';
import { generateMarketingWebsiteURL } from '@/utils/v1-utils';

const DefaultResetAction = () => {
  const { t } = useTranslation();

  return (
    <Button variant="contained" component={NavLink} to="/">
      {t('back-to-home', { defaultValue: 'Back to home' })}
    </Button>
  );
};

export default function NotFoundErrorElement({
  mode = 'component',
  title,
  description,
  actions = <DefaultResetAction />,
  error,
  componentStackTrace,
}: {
  mode?: 'page' | 'component';
  title?: string;
  description?: React.ReactNode;
  actions?: React.ReactNode;
  error?: Error;
  componentStackTrace?: string;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <CommonErrorStateLayout
      mode={mode}
      title={
        title ??
        t('page-not-found-title', {
          defaultValue: 'Page not found',
        })
      }
      description={
        description ?? (
          <Trans
            i18nKey="page-not-found-description"
            defaults="There is nothing at this URL.<br/>Try checking the address or <2>get help</2>"
          >
            There is nothing at this URL. Try checking the address or{' '}
            <Typography
              variant="body1"
              sx={{ textDecoration: 'none' }}
              component="a"
              color="blue.90"
              href={generateMarketingWebsiteURL({
                language,
                path: '/contact',
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              get help
            </Typography>
          </Trans>
        )
      }
      icon={
        <Stack
          sx={{
            display: 'inline-flex',
            padding: '6px',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 999,
            backgroundColor: 'gray.10',
          }}
        >
          <Icon icon="x-close" size={20} sx={{ color: 'darkBlue.70' }} />
        </Stack>
      }
      actions={
        <>
          {actions}
          {import.meta.env.DEV && error ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              spacing={2}
              mt="16px"
            >
              <Typography variant="headline2">{error.toString()}</Typography>
              <Stack
                border={1}
                borderColor="gray.30"
                borderRadius="8px"
                bgcolor="blue.10"
              >
                <ScrollArea maxHeight={300}>
                  <Typography component="pre">
                    {componentStackTrace ?? error.stack}
                  </Typography>
                </ScrollArea>
              </Stack>
            </Stack>
          ) : null}
        </>
      }
    />
  );
}
