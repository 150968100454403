import dayjs from 'dayjs';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getICUConfig, icu } from '@/i18n';

const handleLanguageChange = (lng: string) => {
  dayjs.locale(lng.toLowerCase());
  icu.addUserDefinedFormats(getICUConfig(lng));
};

export function useLocaleSetup() {
  const { i18n } = useTranslation();

  useLayoutEffect(() => {
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
