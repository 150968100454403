import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';

import { useAxios } from '@/api/axiosClient';
import type {
  AssignmentRule,
  AutoTopUpMutationParam,
  AutoTopUpOptionsResponse,
  AutoTopUpProfileResponse,
  CloudApiBalanceResponse,
  Company,
  CompanyHashtag,
  ConnectTelegramResponse,
  ConnectWhatsappCloudApiResponse,
  CreateUpdateAssignmentRule,
  CustomUserProfileFields,
  Delete360ApiChannelRequest,
  DeleteCloudApiChannelRequest,
  DeleteFacebookChannelRequest,
  DeleteInstagramChannelRequest,
  DeleteLineChannelRequest,
  DeleteSmsChannelRequest,
  DeleteTelegramChannelRequest,
  DeleteTwilioChannelRequest,
  DeleteViberChannelRequest,
  DeleteWeChatChannelRequest,
  GetWabaChannelsResponse,
  Rename360DialogChannelRequest,
  RenameCloudApiChannelRequest,
  RenameFacebookChannelRequest,
  RenameLineChannelRequest,
  RenameSmsChannelRequest,
  RenameTelegramChannelRequest,
  RenameTwilioChannelRequest,
  RenameViberChannelRequest,
  RenameWeChatChannelRequest,
  Staff,
  Team,
  UploadWhatsapp360DialogMediaFileRequest,
  UploadWhatsapp360DialogMediaFileResponse,
  UploadWhatsappCloudMediaFileRequest,
  UploadWhatsappCloudMediaFileResponse,
  Whatsapp360DialogTemplateResponse,
  WhatsappCloudTemplateResponse,
} from '@/api/types';
import {
  CompanyUsageResponse,
  CustomUserProfileFieldOptions,
  DeleteWhatsapp360DialogMediaFileRequest,
  DeleteWhatsapp360DialogMediaFileResponse,
  QuickReplyResponse,
} from '@/api/types';
import { CloudAPIHeaderType } from '@/pages/Settings/SettingsPlansBillings/Invoices/hooks/useGetCloudAPITopUp';
import { CloudAPIInvoiceResponse } from '@/pages/Settings/SettingsPlansBillings/Invoices/types/invoiceTypes';

export const companyKeys = createQueryKeys('company', {
  company: null,
  getAssignmentRules: ({
    offset = 0,
    limit = 500,
    triggerType,
  }: GetAssignmentRulesParams = {}) => [{ offset, limit, triggerType }],
  getStaffById: ({ userId }: { userId: string }) => [{ userId }],
  getCompanyTags: ({
    offset = 0,
    limit = 500,
    keyword,
    hashTagType,
  }: GetCompanyTagsParams = {}) => [{ offset, limit, hashTagType, keyword }],
  getTeamList: ({ offset = 0, limit = 300 }: GetTeamListParams = {}) => [
    { offset, limit },
  ],
  getAllStaff: ({ offset = 0, limit = 1000 }: GetAllStaffParams = {}) => [
    { offset, limit },
  ],
  getTeamListV1: ({ offset = 0, limit = 300 }: GetTeamListParams = {}) => [
    { offset, limit },
  ],
  getWhatsapp360DialogTemplate: ({
    offset,
    limit,
    whatsapp360DialogConfigId,
  }: Omit<GetWhatsapp360DialogTemplateParams, 'whatsapp360DialogConfigId'> & {
    whatsapp360DialogConfigId: string;
  }) => [{ offset, limit, whatsapp360DialogConfigId }],
  getWhatsAppCloudApiTemplate: ({ wabaId }: { wabaId: string }) => [{ wabaId }],
  getWebHookUrl: (type) => [type],
  getConnectUrl: (type) => [type],
  getWabaChannels: (type) => [type],
  getThreadChannels: (threadId) => [threadId],
  getCompanyUsage: null,
  getCollaborators: ({
    offset = 0,
    limit = 1000,
  }: GetCollaboratorsParams = {}) => [{ offset, limit }],
  getSavedReplies: ({
    offset = 0,
    limit = 300,
    conversationId,
    keyword = '',
  }: {
    offset: number;
    limit: number;
    conversationId: string;
    keyword: string;
  }) => [{ offset, limit, conversationId, keyword }],
  getCloudApiBalance: null,
  getAutoTopProfile: (facebookBusinessId) => [facebookBusinessId],
  getAutoTopUpOptions: null,
  getCloudAPIInvoice: null,
});

export function useCompany<T = Company>({
  suspense,
  select,
  enabled,
}: {
  suspense?: boolean;
  select?: (data: Company) => T;
  enabled?: boolean;
} = {}): UseQueryResult<T, unknown> {
  const url = '/company';
  const axiosClient = useAxios();
  return useQuery(
    companyKeys.company,
    async ({ signal }) => {
      const response = await axiosClient.get<Company>(url, {
        signal,
      });
      return response.data;
    },
    {
      meta: {
        url,
        description: 'Gets Company information',
      },
      suspense,
      select,
      enabled,
      staleTime: 120000, // Avoid frequent api calls as company data is less change
    },
  );
}

export const TRIGGER_TYPE = {
  assignment: 0,
  fieldValueChanged: 1,
  messageReceived: 2,
  recurringJob: 3,
  scheduledJob: 4,
  contactAdded: 5,
  webhookTrigger: 6,
  newContactMessage: 7,
  shopifyNewCustomerTrigger: 8,
  shopifyNewOrUpdatedOrderTrigger: 9,
  shopifyNewAbandonedCart: 10,
  qRCodeAssigneeMapping: 11,
  facebookNewLeadgen: 12,
  qRCodeAssignTeamMapping: 13,
  zapierContactUpdated: 14,
  shopifyUpdatedCustomerTrigger: 15,
  facebookPostComment: 112,
  instagramMediaComment: 122,
  facebookIcebreaker: 111,
  instagramIcebreaker: 121,
  crmHubOnEntityCreated: 200,
  crmHubOnEntityFieldsChanged: 201,
  facebookLiveComment: 110,
  instagramLiveComment: 120,
  crmHubContactUpdated: 202,
  zapierNewIncomingMessage: 211,
  outgoingMessageTrigger: 311,
} as const;

type TriggerType = typeof TRIGGER_TYPE[keyof typeof TRIGGER_TYPE];

interface GetAssignmentRulesParams {
  offset?: number;
  limit?: number;
  triggerType?: TriggerType;
}

interface GetCompanyTagsParams {
  offset?: number;
  limit?: number;
  hashTagType?: 'Normal' | 'Shopify';
  keyword?: string;
}

interface UpdateAssignmentRuleStatus {
  assignmentId: string;
  status: 0 | 1;
}

export interface ReorderAssignmentRuleParams {
  assignmentId: string;
  order: number;
}

export interface HashTagNormalizedType {
  id?: string;
  hashtag: string;
  hashTagColor: string;
}

export const useAssignmentRule = <T = AssignmentRule[]>(
  { offset = 0, limit = 500, triggerType }: GetAssignmentRulesParams,
  {
    select,
  }: {
    select?: (data: AssignmentRule[]) => T;
  },
) => {
  const axiosClient = useAxios();
  const url = '/Company/AssignmentRule';
  return useQuery({
    queryKey: companyKeys.getAssignmentRules({ offset, limit, triggerType }),
    queryFn: async ({ signal }) => {
      const response = await axiosClient.get<AssignmentRule[]>(url, {
        signal,
        params: { offset, limit, triggerType },
      });
      return response.data;
    },
    select,
    meta: {
      url,
    },
  });
};

export const useDeleteAssignmentRules = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: string[]) => unknown;
  onSuccess?: (data: unknown, variables: string[]) => void;
  onError?: (
    error: unknown,
    variables: string[],
    context: unknown | undefined,
  ) => void;
} = {}) => {
  const axiosClient = useAxios();
  const url = '/Company/AssignmentRule';
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, string[], unknown>({
    mutationFn: (assignmentRuleIds) => {
      return axiosClient.delete(url, { data: { assignmentRuleIds } });
    },
    onMutate,
    onSettled: () => {
      queryClient.invalidateQueries(companyKeys.getAssignmentRules._def);
    },
    onSuccess,
    onError,
  });
};

export const useUndoDeleteAssignmentRules = ({
  onMutate,
}: {
  onMutate?: (variables: string[]) => unknown;
} = {}) => {
  const axiosClient = useAxios();
  const url = '/Company/AssignmentRule/Delete/Undo';
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, string[], unknown>({
    mutationFn: (assignmentRuleIds) => {
      return axiosClient.post(url, { assignmentRuleIds });
    },
    onMutate,
    onSettled: () => {
      queryClient.invalidateQueries(companyKeys.getAssignmentRules._def);
    },
  });
};

export const useReorderAssignmentRule = ({
  onMutate,
}: {
  onMutate?:
    | ((variables: {
        data: ReorderAssignmentRuleParams[];
        oldIndex?: number | undefined;
        newIndex?: number | undefined;
      }) => unknown)
    | undefined;
}) => {
  const queryClient = useQueryClient();
  const axiosClient = useAxios();
  const url = '/Company/AssignmentRule/reorder';
  return useMutation<
    unknown,
    unknown,
    {
      data: ReorderAssignmentRuleParams[];
      oldIndex?: number;
      newIndex?: number;
    },
    unknown
  >({
    mutationFn: async (data) => {
      const response = await axiosClient.post(url, data.data);
      return response.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries(companyKeys.getAssignmentRules._def);
    },
    onMutate,
    onError: (error, variables, context) => {
      const contextCast = context as { previousData?: unknown };
      if (contextCast?.previousData) {
        queryClient.setQueryData(
          companyKeys.getAssignmentRules(),
          contextCast.previousData,
        );
      }
    },
  });
};

export const useCreateUpdateAssignmentRule = ({
  onMutate,
}: {
  onMutate?: (variables: CreateUpdateAssignmentRule[]) => unknown;
} = {}) => {
  const url = '/Company/AssignmentRule';
  const axiosClient = useAxios();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, CreateUpdateAssignmentRule[], unknown>({
    mutationFn: async (data) => {
      const response = await axiosClient.post(url, data);
      return response.data;
    },
    onMutate,
    onSettled: () => {
      queryClient.invalidateQueries(companyKeys.getAssignmentRules._def);
    },
    onError: (error, variables, context) => {
      const contextCast = context as { restoreCache?: () => void };
      // if mutation fails restore previous data taken from onMutate function
      if (contextCast?.restoreCache) {
        contextCast.restoreCache();
      }
    },
  });
};

export const useUpdateAssignmentRuleStatus = ({
  onMutate,
}: {
  onMutate: (variables: UpdateAssignmentRuleStatus[]) => unknown;
}) => {
  const url = '/Company/AssignmentRule/status';
  const axiosClient = useAxios();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateAssignmentRuleStatus[], unknown>({
    mutationFn: async (data) => {
      const response = await axiosClient.post(url, data);
      return response.data;
    },
    onMutate,
    onSettled: () => {
      queryClient.invalidateQueries(companyKeys.getAssignmentRules._def);
    },
    onError: (error, variables, context) => {
      const contextCast = context as { restoreCache?: () => void };
      // if mutation fails restore previous data taken from onMutate function
      if (contextCast?.restoreCache) {
        contextCast.restoreCache();
      }
    },
  });
};

export function useGetStaffById<T = Staff[]>({
  userId,
  select,
  suspense,
  staleTime = 0,
}: {
  suspense?: boolean;
  userId: string;
  select?: (data: Staff[]) => T;
  staleTime?: number;
}) {
  const url = `v2/Company/Staff/${userId}`;
  const axiosClient = useAxios();
  return useQuery({
    suspense,
    queryKey: companyKeys.getStaffById({ userId }),
    queryFn: async ({ signal }) => {
      const response = await axiosClient.get<Staff[]>(url, { signal });

      const result = response.data.map((staff) => ({
        ...staff,
        name: staff.name?.trim() ? staff.name.trim() : staff.userInfo.userName,
      }));

      return result;
    },
    meta: {
      url,
      description: 'Get staff by userId',
    },
    select,
    staleTime,
  });
}

interface GetAllStaffParams {
  offset?: number;
  limit?: number;
  staffName?: string;
}

export function useGetAllStaff<T = Staff[]>({
  offset = 0,
  limit = 1000,
  select,
  enabled,
}: {
  offset?: number;
  limit?: number;
  select?: (data: Staff[]) => T;
  enabled?: boolean;
} = {}) {
  const url = '/Company/Staff';
  const axiosClient = useAxios();
  return useQuery({
    queryKey: companyKeys.getAllStaff({ offset, limit }),
    queryFn: async ({ signal }) => {
      const response = await axiosClient.get<Staff[]>(url, {
        signal,
        params: {
          offset,
          limit,
        },
      });
      const result = response.data.map((staff) => ({
        ...staff,
        name: staff.name?.trim() ? staff.name?.trim() : staff.userInfo.userName,
      }));

      return result;
    },
    meta: {
      url,
      description: 'Get all staff',
    },
    select,
    staleTime: Infinity,
    enabled,
  });
}

export function useCompanyTags<T = CompanyHashtag[]>({
  offset = 0,
  limit = 500,
  keyword,
  hashTagType,
  select,
  enabled = true,
}: GetCompanyTagsParams & {
  select?: (data: CompanyHashtag[]) => T;
  enabled?: boolean;
} = {}) {
  const url = '/Company/Tags';
  const axiosClient = useAxios();
  return useQuery({
    enabled,
    queryKey: companyKeys.getCompanyTags({
      offset,
      limit,
      keyword,
      hashTagType,
    }),
    queryFn: async ({ signal }) => {
      const response = await axiosClient.get<CompanyHashtag[]>(url, {
        signal,
        params: {
          offset,
          limit,
          keyword,
          hashTagType,
        },
      });
      return response.data;
    },
    select,
    meta: {
      url,
      description: 'Get company tags',
    },
  });
}

interface GetTeamListParams {
  offset?: number;
  limit?: number;
}
interface GetCollaboratorsParams {
  offset?: number;
  limit?: number;
}
// Note: `members` will always be an empty array
export function useTeamList<T = Team[]>({
  offset = 0,
  limit = 300,
  select,
  enabled,
}: GetTeamListParams & {
  select?: (data: Team[]) => T;
  enabled?: boolean;
} = {}): UseQueryResult<T> {
  const axiosClient = useAxios();
  const url = '/v2/Company/Team';
  return useQuery({
    queryKey: companyKeys.getTeamList({ offset, limit }),
    queryFn: async ({ signal }) => {
      const response = await axiosClient.get<Team[]>(url, {
        signal,
        params: { offset, limit },
      });
      return response.data;
    },
    select,
    enabled,
  });
}

export function useTeamListV1<T = Team[]>({
  offset = 0,
  limit = 300,
  select,
  enabled,
}: GetTeamListParams & {
  select?: (data: Team[]) => T;
  enabled?: boolean;
} = {}): UseQueryResult<T> {
  const axiosClient = useAxios();
  const url = '/Company/Team';
  return useQuery({
    queryKey: companyKeys.getTeamListV1({ offset, limit }),
    queryFn: async ({ signal }) => {
      const response = await axiosClient.get<Team[]>(url, {
        signal,
        params: { offset, limit },
      });
      return response.data;
    },
    select,
    enabled,
  });
}

export const useDeleteUserProfileField = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: string[]) => unknown;
  onSuccess?: (data: unknown, variables: string[]) => void;
  onError?: (
    error: unknown,
    variables: string[],
    context: unknown | undefined,
  ) => void;
} = {}) => {
  const axiosClient = useAxios();
  const url = '/Company/UserProfileField';
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userProfileIds) => {
      return axiosClient.delete(url, {
        data: { userProfileFieldIds: userProfileIds },
      });
    },
    onMutate,
    onSettled: () => {
      queryClient.invalidateQueries(companyKeys.company);
    },
    onSuccess,
    onError,
  });
};

interface GetWhatsapp360DialogTemplateParams {
  whatsapp360DialogConfigId: string;
  limit?: number;
  offset?: number;
}

export const useWhatsapp360DialogTemplateQuery = <
  T = Whatsapp360DialogTemplateResponse,
>({
  select,
  whatsapp360DialogConfigId,
  limit = 1000,
  offset = 0,
  enabled,
}: GetWhatsapp360DialogTemplateParams & {
  select?: (data: Whatsapp360DialogTemplateResponse) => T;
  enabled?: boolean;
}) => {
  const axiosClient = useAxios();
  return useQuery({
    queryKey: companyKeys.getWhatsapp360DialogTemplate({
      offset,
      limit,
      whatsapp360DialogConfigId,
    }),
    queryFn: async ({ signal }) => {
      const url = `/Company/Whatsapp/360dialog/${whatsapp360DialogConfigId}/Template`;
      const response = await axiosClient.get(url, {
        signal,
        params: { offset, limit },
      });
      return response.data;
    },
    select,
    enabled,
    useErrorBoundary: false,
  });
};

export const useWhatsappCloudApiTemplateQuery = <
  T = WhatsappCloudTemplateResponse,
>({
  select,
  wabaId,
  enabled,
}: {
  wabaId: string;
  select?: (data: WhatsappCloudTemplateResponse) => T;
  enabled?: boolean;
}) => {
  const axiosClient = useAxios();
  return useQuery({
    queryKey: companyKeys.getWhatsAppCloudApiTemplate({
      wabaId,
    }),
    queryFn: async ({ signal }) => {
      const url = `/company/whatsapp/cloudapi/template`;
      const response = await axiosClient.get(url, {
        signal,
        params: { wabaId },
      });
      return response.data;
    },
    select,
    enabled,
    useErrorBoundary: false,
  });
};

export const useUploadWhatsapp360DialogMediaFileMutation = ({
  onMutate,
  onSuccess,
}: {
  onMutate?: (variables: UploadWhatsapp360DialogMediaFileRequest) => unknown;
  onSuccess?: (
    data: UploadWhatsapp360DialogMediaFileResponse,
    variables: UploadWhatsapp360DialogMediaFileRequest,
  ) => void;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/Whatsapp/360dialog/${data.whatsapp360DialogConfigId}/File`;
      const formData = new FormData();
      data.displayName && formData.append('displayName', data.displayName);
      formData.append(
        'whatsappMediaType',
        data.whatsappMediaType.toLowerCase(),
      );
      formData.append('file', data.file);
      formData.append('isTemplateFile', JSON.stringify(data.isTemplateFile));
      const response =
        await axiosClient.post<UploadWhatsapp360DialogMediaFileResponse>(
          url,
          formData,
        );
      return response.data;
    },
    onMutate,
    onSuccess,
  });
};

export const useDeleteWhatsapp360DialogMediaFileMutation = ({
  onMutate,
  onSuccess,
}: {
  onMutate?: (variables: DeleteWhatsapp360DialogMediaFileRequest) => unknown;
  onSuccess?: (
    data: DeleteWhatsapp360DialogMediaFileResponse,
    variables: DeleteWhatsapp360DialogMediaFileRequest,
  ) => void;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/Whatsapp/360dialog/${data.whatsapp360DialogConfigId}/File/${data.fileId}`;
      const response =
        await axiosClient.delete<DeleteWhatsapp360DialogMediaFileResponse>(url);
      return response.data;
    },
    onMutate,
    onSuccess,
  });
};

export const useDeleteWhatsapp360DialogMediaFileWithoutConfigIdMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: { fileId: string }) => unknown;
  onSuccess?: (
    data: DeleteWhatsapp360DialogMediaFileResponse,
    variables: { fileId: string },
  ) => void;

  onError?:
    | ((
        error: unknown,
        variables: { fileId: string },
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/Whatsapp/360dialog/File/${data.fileId}`;
      const response =
        await axiosClient.delete<DeleteWhatsapp360DialogMediaFileResponse>(url);
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useUploadWhatsappCloudMediaFileMutation = ({
  onMutate,
  onSuccess,
}: {
  onMutate?: (variables: UploadWhatsappCloudMediaFileRequest) => unknown;
  onSuccess?: (
    data: UploadWhatsappCloudMediaFileResponse,
    variables: UploadWhatsappCloudMediaFileRequest,
  ) => void;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = '/ExtendedMessage/File';
      const formData = new FormData();
      data.DisplayName && formData.append('DisplayName', data.DisplayName);
      formData.append('ExtendedMessageType', data.ExtendedMessageType);
      formData.append('MediaType', data.MediaType.toLowerCase());
      formData.append('Channel', data.Channel);
      formData.append('File', data.File);
      formData.append('IsTemplateFile', JSON.stringify(data.IsTemplateFile));
      const response =
        await axiosClient.post<UploadWhatsappCloudMediaFileResponse>(
          url,
          formData,
        );
      return response.data;
    },
    onMutate,
    onSuccess,
  });
};

export const useDeleteWhatsappCloudMediaFileMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onError?:
    | ((
        error: unknown,
        variables: { fileId: string },
        context: unknown,
      ) => unknown)
    | undefined;
  onSuccess?:
    | ((
        data: { message: string },
        variables: { fileId: string },
        context: unknown,
      ) => unknown)
    | undefined;
  onMutate?: ((variables: { fileId: string }) => unknown) | undefined;
}) => {
  const axiosClient = useAxios();

  return useMutation({
    mutationFn: async (data: { fileId: string }) => {
      const url = `/ExtendedMessage/File/${data.fileId}`;
      const response = await axiosClient.delete<{ message: string }>(url);
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export type UserProfileFieldsMutationParams = Partial<
  Omit<CustomUserProfileFields, 'customUserProfileFieldOptions'> & {
    customUserProfileFieldOptions: Omit<CustomUserProfileFieldOptions, 'id'>[];
  }
>[];

export const useUserProfileFieldsMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess:
    | ((
        data: Company,
        variables: UserProfileFieldsMutationParams,
        context: unknown,
      ) => unknown)
    | undefined;
  onError:
    | ((
        error: unknown,
        variables: UserProfileFieldsMutationParams,
        context: unknown,
      ) => unknown)
    | undefined;
}) => {
  const url = '/Company/UserProfileFields';
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data: UserProfileFieldsMutationParams) => {
      const resp = await axiosClient.post<Company>(url, data);
      return resp.data;
    },
    onSuccess,
    onError,
  });
};

export enum AppearOnlineDict {
  Active = 'Active',
  Away = 'Away',
}
export function useStaffProfileMutation({
  onError,
}: {
  onError?:
    | ((
        error: unknown,
        variables: {
          [key: string]: any;
        },
        context: unknown,
      ) => unknown)
    | undefined;
}) {
  const queryClient = useQueryClient();
  const axiosClient = useAxios();

  return useMutation({
    mutationFn: async (data: { [key: string]: any; userId: string }) => {
      const url = `Company/Staff/${data.userId}`;
      const response = await axiosClient.post<Staff>(url, data);
      return response.data;
    },

    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(
        companyKeys.getStaffById({ userId: variables.userId }),
      );
    },
    onError,
  });
}

export function useGetWabaChannelsQuery<T = GetWabaChannelsResponse>({
  select,
}: {
  select?: (data: GetWabaChannelsResponse) => T;
}) {
  const axiosClient = useAxios();
  return useQuery(
    companyKeys.getWabaChannels('waba'),
    async () => {
      const response = await axiosClient.get<GetWabaChannelsResponse>(
        '/company/whatsapp/cloudapi/channel',
      );
      return response.data;
    },
    {
      select,
    },
  );
}

export const useCompanyUsageQuery = <T = CompanyUsageResponse>(options?: {
  suspense?: boolean;
  select?: (data: CompanyUsageResponse) => T;
}) => {
  const url = '/company/usage';
  const axiosClient = useAxios();
  return useQuery({
    suspense: options?.suspense,
    queryKey: companyKeys.getCompanyUsage,
    queryFn: async ({ signal }) => {
      const res = await axiosClient.get<CompanyUsageResponse>(url, { signal });
      return res.data;
    },
  });
};

export const useGetQuickRepliesQuery = <T = QuickReplyResponse>({
  conversationId,
  keyword = '',
  offset = 0,
  limit = 300,
  select,
}: {
  conversationId: string;
  keyword?: string;
  offset?: number;
  limit?: number;
  select?: (data: QuickReplyResponse) => T;
}) => {
  const axiosClient = useAxios();
  const url = `/Company/QuickReply/Text`;

  return useQuery({
    queryKey: companyKeys.getSavedReplies({
      conversationId,
      keyword,
      offset,
      limit,
    }),
    queryFn: async () => {
      const response = await axiosClient.get<QuickReplyResponse>(url, {
        params: {
          conversationId,
          keyword,
          offset,
          limit,
        },
      });
      return response.data;
    },
    select,
  });
};

interface UpdateCompanyParams {
  companyName?: string;
  timeZone?: number;
  timeZoneInfoId?: string;
}

export function useUpdateCompanyMutation({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) {
  const axiosClient = useAxios();

  return useMutation({
    mutationFn: async ({
      companyName,
      timeZone,
      timeZoneInfoId,
    }: UpdateCompanyParams) => {
      const url = `Company/Update`;
      const response = await axiosClient.post(url, {
        companyName,
        timeZone,
        timeZoneInfoId,
      });
      return response.data;
    },
    onSuccess,
    onError,
  });
}
export const useGetCloudApiBalanceRecordsQuery = <T = CloudApiBalanceResponse>({
  select,
}: {
  select?: (data: CloudApiBalanceResponse) => T;
} = {}) => {
  const url = '/company/whatsapp/cloudapi/balances';
  const axiosClient = useAxios();
  return useQuery({
    queryKey: companyKeys.getCloudApiBalance,
    queryFn: async () => {
      const response = await axiosClient.get<CloudApiBalanceResponse>(url);
      return response.data;
    },
    select,
    meta: {
      url,
      description: 'Gets cloud api auto top up records',
    },
  });
};

export const useGetAutoTopUpProfileQuery = <T = AutoTopUpProfileResponse>(
  facebookBusinessId: string,
  {
    enabled,
    select,
  }: {
    select?: (data: AutoTopUpProfileResponse) => T;
    enabled?: boolean;
  },
) => {
  const axiosClient = useAxios();
  const url = `/company/whatsapp/cloudapi/auto-top-up/${facebookBusinessId}`;
  return useQuery({
    queryKey: companyKeys.getAutoTopProfile(facebookBusinessId),
    queryFn: async () => {
      const response = await axiosClient.get<AutoTopUpProfileResponse>(url);
      return response.data;
    },
    select,
    enabled,
    meta: {
      url,
      description: 'Gets cloud api auto top up profile',
    },
  });
};

export const useAutoTopUpMutation = () => {
  const axiosClient = useAxios();
  const url = '/company/whatsapp/cloudapi/auto-top-up';
  return useMutation({
    mutationFn: (params: AutoTopUpMutationParam) => {
      return axiosClient.put(url, params);
    },
  });
};

export const useGetAutoTopUpSettingOptionsQuery = <
  T = AutoTopUpOptionsResponse,
>({ select }: { select?: (data: AutoTopUpOptionsResponse) => T } = {}) => {
  const axiosClient = useAxios();
  const url = '/company/whatsapp/cloudapi/auto-top-up/settings';
  return useQuery({
    queryKey: companyKeys.getAutoTopUpOptions,
    queryFn: async () => {
      const respone = await axiosClient.get(url);
      return respone.data;
    },
    select,
    meta: {
      url,
      description: 'Gets cloud api auto top up settings',
    },
  });
};

export function useConnectTelegramMutation() {
  const axiosClient = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: {
      telegramBotToken: string;
      displayName: string;
    }) => {
      return await axiosClient.post<ConnectTelegramResponse>(
        '/Company/telegram/connect',
        variables,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(companyKeys.company);
    },
  });
}

export function useGetLineWebhookUrlQuery() {
  const axiosClient = useAxios();

  return useQuery({
    queryKey: companyKeys.getWebHookUrl('line'),
    queryFn: async () => {
      const response = await axiosClient.get('/Company/line/webhookURL');
      return response.data.Url;
    },
  });
}
export function useConnectLineMutation() {
  const axiosClient = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: {
      name: string;
      basicId: string;
      channelId: string;
      channelSecret: string;
    }) => {
      // TODO: response type
      return axiosClient.post('/Company/line/connect', data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(companyKeys.company);
    },
  });
}

export const useConnectViberMutation = () => {
  const axiosClient = useAxios();
  const url = '/Company/viber/connect';
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: {
      displayName: string;
      viberBotSenderName: string;
      botToken: string;
    }) => {
      //TODO add response type
      const response = await axiosClient.post(url, payload);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(companyKeys.company);
    },
  });
};

export function useConnectWhatsappMutation() {
  const axiosClient = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: {
      channelName: string;
      wabaId: string;
      wabaPhoneNumberId: string;
    }) => {
      return await axiosClient.post<ConnectWhatsappCloudApiResponse>(
        '/company/whatsapp/cloudapi/channel',
        data,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(companyKeys.company);
    },
  });
}

export function useCloudAPIConnectWABAMutation() {
  const axiosClient = useAxios();

  return useMutation({
    mutationFn: async (authorizationCode: string) => {
      const response = await axiosClient.post<{
        connectedWaba: Array<{
          facebookWabaName: string;
          facebookWabaAccountReviewStatus: string;
          facebookWabaId: string;
        }>;
      }>(
        '/company/whatsapp/cloudapi/waba/exchange-facebook-authorization-code',
        {
          facebook_authorization_code: authorizationCode,
        },
      );
      if (!response.data?.connectedWaba.length) {
        throw new Error('no connected channel');
      }
    },
  });
}

export const useDeleteTwilioChannelMutation = ({
  onMutate,
  onSuccess,
}: {
  onMutate?: (variables: DeleteTwilioChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: DeleteTwilioChannelRequest) => void;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/twilio/whatsapp/${data.twilioAccountId}`;
      const response = await axiosClient.delete(url);
      return response.data;
    },
    onMutate,
    onSuccess,
  });
};

export const useRenameTwilioChannelMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: RenameTwilioChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: RenameTwilioChannelRequest) => void;
  onError?:
    | ((
        error: unknown,
        variables: RenameTwilioChannelRequest,
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `Company/twilio/whatsapp/update/`;
      const response = await axiosClient.post(
        url,
        {
          name: data.newName,
        },
        {
          params: {
            sid: data.twilioAccountId,
          },
        },
      );
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useDeleteCloudApiChannelMutation = ({
  onMutate,
  onSuccess,
}: {
  onMutate?: (variables: DeleteCloudApiChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: DeleteCloudApiChannelRequest) => void;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/company/whatsapp/cloudapi/channel`;
      const response = await axiosClient.delete(url, {
        data: {
          wabaId: data.messagingHubWabaId,
          wabaPhoneNumberId: data.messagingHubWabaPhoneNumberId,
        },
      });
      return response.data;
    },
    onMutate,
    onSuccess,
  });
};

export const useRenameCloudApiChannelMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: RenameCloudApiChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: RenameCloudApiChannelRequest) => void;
  onError?:
    | ((
        error: unknown,
        variables: RenameCloudApiChannelRequest,
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = '/company/whatsapp/cloudapi/channel';
      const response = await axiosClient.put(url, {
        channelName: data.newName,
        wabaId: data.messagingHubWabaId,
        wabaPhoneNumberId: data.messagingHubWabaPhoneNumberId,
      });
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useDelete360DialogChannelMutation = ({
  onMutate,
  onSuccess,
}: {
  onMutate?: (variables: Delete360ApiChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: Delete360ApiChannelRequest) => void;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/company/whatsapp/360dialog/${data.whatsApp360DialogConfigId}`;
      const response = await axiosClient.delete(url);
      return response.data;
    },
    onMutate,
    onSuccess,
  });
};

export const useRename360DialogChannelMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: Rename360DialogChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: Rename360DialogChannelRequest) => void;
  onError?:
    | ((
        error: unknown,
        variables: Rename360DialogChannelRequest,
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/company/whatsapp/360dialog/${data.id}`;
      const response = await axiosClient.put(url, {
        channelName: data.newName,
      });
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useDeleteSmsChannelMutation = ({
  onMutate,
  onSuccess,
}: {
  onMutate?: (variables: DeleteSmsChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: DeleteSmsChannelRequest) => void;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/twilio/sms/${data.twilioAccountId}`;
      const response = await axiosClient.delete(url);
      return response.data;
    },
    onMutate,
    onSuccess,
  });
};

export const useRenameSmsChannelMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: RenameSmsChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: RenameSmsChannelRequest) => void;
  onError?:
    | ((
        error: unknown,
        variables: RenameSmsChannelRequest,
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/twilio/sms/rename/${data.twilioAccountId}`;
      const response = await axiosClient.post(url, {
        name: data.newName,
      });
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useDeleteFacebookChannelMutation = ({
  onMutate,
  onSuccess,
}: {
  onMutate?: (variables: DeleteFacebookChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: DeleteFacebookChannelRequest) => void;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/Facebook/${data.pageId}`;
      const response = await axiosClient.delete(url);
      return response.data;
    },
    onMutate,
    onSuccess,
  });
};

export const useRenameFacebookChannelMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: RenameFacebookChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: RenameFacebookChannelRequest) => void;
  onError?:
    | ((
        error: unknown,
        variables: RenameFacebookChannelRequest,
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/Facebook/rename/${data.pageId}`;
      const response = await axiosClient.post(url, {
        name: data.newName,
      });
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useDeleteInstagramChannelMutation = ({
  onMutate,
  onSuccess,
}: {
  onMutate?: (variables: DeleteInstagramChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: DeleteInstagramChannelRequest) => void;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/instagram/${data.instagramPageId}`;
      const response = await axiosClient.delete(url);
      return response.data;
    },
    onMutate,
    onSuccess,
  });
};

export const useDeleteLineChannelMutation = ({
  onMutate,
  onSuccess,
}: {
  onMutate?: (variables: DeleteLineChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: DeleteLineChannelRequest) => void;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/company/line/${data.channelID}`;
      const response = await axiosClient.delete(url);
      return response.data;
    },
    onMutate,
    onSuccess,
  });
};

export const useRenameLineChannelMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: RenameLineChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: RenameLineChannelRequest) => void;
  onError?:
    | ((
        error: unknown,
        variables: RenameLineChannelRequest,
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/line/rename/${data.channelID}`;
      const response = await axiosClient.post(url, {
        name: data.newName,
      });
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useDeleteWeChatChannelMutation = ({
  onMutate,
  onSuccess,
}: {
  onMutate?: (variables: DeleteWeChatChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: DeleteWeChatChannelRequest) => void;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/wechat/${data.appId}`;
      const response = await axiosClient.delete(url);
      return response.data;
    },
    onMutate,
    onSuccess,
  });
};

export const useRenameWeChatChannelMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: RenameWeChatChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: RenameWeChatChannelRequest) => void;
  onError?:
    | ((
        error: unknown,
        variables: RenameWeChatChannelRequest,
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = `/Company/wechat/rename/${data.appId}`;
      const response = await axiosClient.post(url, {
        name: data.newName,
      });
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useDeleteTelegramChannelMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: DeleteTelegramChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: DeleteTelegramChannelRequest) => void;
  onError?:
    | ((
        error: unknown,
        variables: DeleteTelegramChannelRequest,
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = '/Company/telegram';
      const response = await axiosClient.delete(url, {
        data: { telegramChannelId: data.id },
      });
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useRenameTelegramChannelMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: RenameTelegramChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: RenameTelegramChannelRequest) => void;
  onError?:
    | ((
        error: unknown,
        variables: RenameTelegramChannelRequest,
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = '/Company/telegram';
      const response = await axiosClient.put(url, {
        telegramChannelId: data.id,
        displayName: data.newName,
      });
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useDeleteViberChannelMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: DeleteViberChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: DeleteViberChannelRequest) => void;
  onError?:
    | ((
        error: unknown,
        variables: DeleteViberChannelRequest,
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = '/Company/viber';
      const response = await axiosClient.delete(url, {
        data: { viberChannelId: data.id },
      });
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useRenameViberChannelMutation = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate?: (variables: RenameViberChannelRequest) => unknown;
  onSuccess?: (data: unknown, variables: RenameViberChannelRequest) => void;
  onError?:
    | ((
        error: unknown,
        variables: RenameViberChannelRequest,
        context: unknown,
      ) => unknown)
    | undefined;
} = {}) => {
  const axiosClient = useAxios();
  return useMutation({
    mutationFn: async (data) => {
      const url = '/Company/viber';
      const response = await axiosClient.put(url, {
        viberChannelId: data.id,
        displayName: data.newName,
        viberBotSenderName: data.viberBotSenderName,
      });
      return response.data;
    },
    onMutate,
    onSuccess,
    onError,
  });
};

export const useCloudAPIInvoiceQuery = ({
  select,
}: {
  select?: (
    data: CloudAPIInvoiceResponse,
  ) => Record<CloudAPIHeaderType, string>[];
} = {}) => {
  const axiosClient = useAxios();
  const url = '/company/whatsapp/cloudapi/top-up/invoices';
  return useQuery({
    queryKey: companyKeys.getCloudAPIInvoice,
    queryFn: async () => {
      const response = await axiosClient.get<CloudAPIInvoiceResponse>(url, {});
      return response.data;
    },
    select,
  });
};
