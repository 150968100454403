export const ROUTES = {
  inbox: 'inbox',
  automations: 'automations',
  invite: 'invite',
  // Broadcasts
  broadcasts: 'broadcasts',
  broadcastsId: 'broadcasts/:id',
  broadcastChannelCreate: 'broadcasts/:channel',
  broadcastsChannelIdDraft: 'broadcasts/:channel/:templateId/draft',
  broadcastsChannelTemplateIdReview: 'broadcasts/:channel/:templateId/review',
  // Contacts
  contacts: 'contacts',
  contactsTeam: 'contacts/team',
  contactsMyContacts: 'contacts/my-contacts',
  contactsShopify: 'contacts/shopify',
  contactsId: 'contacts/:id',
  contactsIdTab: 'contacts/:id/:tab',
  contactsImport: 'contacts/import',
  contactsCreate: 'contacts/create',
  contactsEditColumns: 'contacts/edit-columns',
  contactsList: 'contacts/list',
  contactsListId: 'contacts/list/:id',
  analytics: 'analytics',
  integrations: 'integrations',
  commerceHub: 'commerce-hub',
  gettingStarted: 'getting-started',
  // Channels
  channels: 'channels',
  whatsapp: 'channels/whatsapp',
  facebook: 'channels/messenger',
  sms: 'channels/sms',
  wechat: 'channels/wechat',
  line: 'channels/line',
  telegram: 'channels/telegram',
  viber: 'channels/viber',
  instagram: 'channels/instagram',
  connectInstagram: 'channels/instagram/connect',
  connectWechat: 'channels/wechat/connect',
  connectLine: 'channels/line/connect',
  connectTelegram: 'channels/telegram/connect',
  connectSMS: 'channels/sms/connect',
  connectViber: 'channels/viber/connect',
  connectFacebook: 'channels/messenger/connect',
  connectWhatsappCloud: 'channels/whatsapp-cloud-api/connect',
  // Flow builder
  flowBuilder: 'flow-builder',
  flowBuilderId: 'flow-builder/:id',
  // Settings
  settings: 'settings',
  settingsCompanyDetail: 'settings/company-details',
  settingsCompanyDetail2FA: 'settings/company-details/2fa',
  settingsCompanyDetailIpWhiteList: 'settings/company-details/ip-whitelist',
  settingsCustomObject: 'settings/custom-objects',
  settingsCustomObjectProperties: 'settings/custom-objects/:id',
  settingsCustomObjectCreate: 'settings/custom-objects/create',
  settingsUserManagement: 'settings/user-management',
  settingsTeamManagement: 'settings/team-management',
  settingsSubscriptions: 'settings/subscriptions',
  settingsInboxSettings: 'settings/inbox-settings',
  settingsPlansAndBillingsInvoices: 'settings/plans-and-billings/invoices',
  // AI Settings
  aiSettings: 'ai-settings',
} as const;
