import {
  Box,
  ListItem,
  Stack,
  styled,
  Tooltip,
  TooltipProps,
  Theme,
} from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { NavLink, To } from 'react-router-dom';

import { ReactComponent as CircleLogo } from '@/assets/logo/sleekflow-logo-circle.svg';
import { ScrollArea } from '@/components/ScrollArea';
import { ROUTES } from '@/constants/navigation';
import { useBroadcastRoleBasedAccessControl } from '@/pages/Broadcasts/hooks/useBroadcastRoleBasedAccessControl';
import { useFlowBuilderRoleBasedAccessControl } from '@/pages/FlowBuilder/hooks/useFlowBuilderRoleBasedAccessControl';
import BackgroundTaskManager from '@/signalr/BackgroundTaskManager/BackgroundTaskManager';

import Icon, { IconProps } from '../Icon';
import SubNavMenu from './SubNavMenu';

export const SIDEBAR_WIDTH = 76;

export type NavItem = {
  to: string;
  label: string;
  icon: IconProps['icon'];
  permission?:
    | keyof ReturnType<typeof useBroadcastRoleBasedAccessControl>
    | keyof ReturnType<typeof useFlowBuilderRoleBasedAccessControl>;
  children?: NavItem[];
  openInNewTab?: boolean;
};

const getNavbarItems = (t: TFunction): NavItem[] => [
  { to: ROUTES.channels, label: t('nav.channels'), icon: 'signal' },
  { to: ROUTES.inbox, label: t('nav.inbox'), icon: 'inbox' },
  { to: ROUTES.contacts, label: t('nav.contacts'), icon: 'contacts' },
  {
    to: ROUTES.broadcasts,
    label: t('nav.broadcasts'),
    icon: 'broadcasts',
    permission: 'canViewBroadcastReview',
  },
  {
    to: ROUTES.flowBuilder,
    label: t('nav.flow-builder'),
    icon: 'dataflow-downwards',
    permission: 'canUseFlow',
  },
  { to: ROUTES.analytics, label: t('nav.analytics'), icon: 'analytics' },
  {
    to: ROUTES.integrations,
    label: t('nav.integrations'),
    icon: 'app-and-integration',
  },
  {
    to: ROUTES.commerceHub,
    label: t('nav.commerce-hub'),
    icon: 'shopping-cart',
  },
  ...(import.meta.env.VITE_USER_NODE_ENV === 'uat'
    ? [
        {
          to: ROUTES.aiSettings,
          label: t('nav.ai-settings'),
          icon: 'magic-wand' as const,
        },
      ]
    : []),
];

const getFooterItems = (t: TFunction): NavItem[] => [
  // TODO: remove when invite users modal is implemented
  { to: ROUTES.invite, label: t('nav.invite-users'), icon: 'users-add' },
  { to: ROUTES.settings, label: t('nav.settings'), icon: 'settings' },
  {
    label: t('nav.help-and-support', { defaultValue: 'help and support' }),
    icon: 'help-circle',
    to: 'help-and-support',
    children: [
      {
        to: 'https://help.sleekflow.io/',
        label: t('nav.help-center', { defaultValue: 'Help center' }),
        icon: 'book-open',
        openInNewTab: true,
      },
      {
        to: 'https://share.hsforms.com/1o8NZAO0mRJuPWLqxeFeDKAcdz4m',
        label: t('nav.submit-ticket', {
          defaultValue: 'Submit support ticket',
        }),
        icon: 'email',
        openInNewTab: true,
      },
    ],
  },
];

export const NavMenuItemToolTip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)({
  '& .MuiTooltip-tooltip': {
    fontSize: 14,
  },
  // Hack to control the offset
  '& .MuiTooltip-tooltipPlacementRight': {
    marginLeft: '8px !important',
  },
});

export default function Navbar() {
  const { t } = useTranslation();
  const broadcastPermission = useBroadcastRoleBasedAccessControl();
  const flowBuilderPermission = useFlowBuilderRoleBasedAccessControl();

  const rbac = { ...broadcastPermission, ...flowBuilderPermission };
  return (
    <Box
      flex={`0 0 ${SIDEBAR_WIDTH}px`}
      component="nav"
      sx={{ zIndex: 1000 }}
      width={SIDEBAR_WIDTH}
      height="100svh"
    >
      <Box
        sx={{
          backgroundColor: 'darkBlue.90',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Box
          display="flex"
          height="100%"
          flex={0}
          alignItems="center"
          position="relative"
          padding="16px"
        >
          <Box
            display="flex"
            alignItems="center"
            padding="0 2px"
            overflow="hidden"
            height={40}
            width={1}
          >
            <CircleLogo width={40} height={40} style={{ flexShrink: 0 }} />
          </Box>
        </Box>
        <ScrollArea sx={{ flex: 'auto' }} onDark>
          <Stack spacing="4px" padding="0 16px">
            {getNavbarItems(t).map((item) => {
              if (
                (item.permission && rbac[item.permission] === undefined) ||
                (item.permission && rbac[item.permission]) ||
                !item.permission
              ) {
                return <NavMenuItem key={item.to} {...item} />;
              }
              return null;
            })}
          </Stack>
        </ScrollArea>
        <Stack spacing="4px" padding="16px" flex={0}>
          <BackgroundTaskManager />
          {getFooterItems(t).map((item) => (
            <NavMenuItem key={item.to} {...item} />
          ))}
        </Stack>
      </Box>
    </Box>
  );
}

export const getNavMenuItemStyles = (theme: Theme, isActive: boolean) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  borderRadius: '8px',
  width: 'auto',
  color: isActive ? theme.palette.white : theme.palette.gray[90],
  backgroundColor: isActive ? theme.palette.darkBlue[80] : 'transparent',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: theme.palette.darkBlue[80],
    color: theme.palette.white,
  },
});

function NavMenuItem({
  label,
  icon,
  to,
  children,
}: {
  to: To;
  label: string;
  icon: IconProps['icon'];
  children?: NavItem[];
}) {
  if (children?.length) {
    return (
      <SubNavMenu title={label} icon={icon}>
        {children}
      </SubNavMenu>
    );
  }
  return (
    <NavMenuItemToolTip
      title={label}
      placement="right"
      enterDelay={100}
      enterNextDelay={100}
    >
      <NavLink to={to} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <ListItem
            component="span"
            disablePadding
            sx={(theme) => getNavMenuItemStyles(theme, isActive)}
          >
            <Icon icon={icon} size={20} sx={{ flexShrink: 0 }} />
          </ListItem>
        )}
      </NavLink>
    </NavMenuItemToolTip>
  );
}
