import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { useAxios } from '@/api/axiosClient';

export const auth0AccountKeys = createQueryKeys('auth0Account', {
  getAuth0AccountIsCompanyRegistered: null,
});

export const useAuth0AccountIsCompanyRegisteredQuery = (options?: {
  suspense?: boolean;
}) => {
  const url = '/auth0/account/IsCompanyRegistered';
  const axiosClient = useAxios();
  return useQuery(
    auth0AccountKeys.getAuth0AccountIsCompanyRegistered,
    async ({ signal }) => {
      const response = await axiosClient.get<boolean>(url, {
        signal,
      });
      return response.data;
    },
    {
      suspense: options?.suspense,
      meta: {
        url,
        description: 'Check if company is registered',
      },
    },
  );
};
