import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useAxios } from '@/api/axiosClient';
import {
  AuditLogsFilterType,
  AuditLogsGetUserProfileAuditLogsResponse,
} from '@/api/types';

export type AuditLogsGetUserProfileAuditLogsArgs = {
  sleekflow_user_profile_id: string;
  continuation_token?: string;
  filters: {
    types?: AuditLogsFilterType[];
    has_sleekflow_staff_id?: boolean;
  };
  limit?: number;
};

export const auditHubKeys = createQueryKeys('auditHub', {
  getAuditLogsGetUserProfileAuditLogs: (
    props: AuditLogsGetUserProfileAuditLogsArgs,
    options: { type?: 'query' | 'infinite' } = {},
  ) => [props, options],
});

export function useAuditLogsGetUserProfileAuditLogsInfiniteQuery<
  T = AuditLogsGetUserProfileAuditLogsResponse,
>(
  {
    sleekflow_user_profile_id,
    filters,
    limit = 10,
  }: Omit<AuditLogsGetUserProfileAuditLogsArgs, 'continuation_token'>,
  options?: {
    enabled?: boolean;
    select?:
      | ((
          data: InfiniteData<AuditLogsGetUserProfileAuditLogsResponse>,
        ) => InfiniteData<T>)
      | undefined;
  },
) {
  const url = 'AuditHub/AuditLogs/GetUserProfileAuditLogsV2';
  const axiosClient = useAxios();
  return useInfiniteQuery(
    auditHubKeys.getAuditLogsGetUserProfileAuditLogs(
      {
        sleekflow_user_profile_id,
        filters,
        limit,
      },
      {
        type: 'infinite',
      },
    ),
    async ({ signal, pageParam }) => {
      const response =
        await axiosClient.post<AuditLogsGetUserProfileAuditLogsResponse>(
          url,
          {
            sleekflow_user_profile_id,
            filters,
            continuation_token: pageParam,
            limit,
          },
          {
            signal,
          },
        );
      return response.data;
    },
    {
      enabled: options?.enabled,
      select: options?.select,
      getNextPageParam: (lastPage) => {
        return lastPage.data?.next_continuation_token;
      },
      meta: {
        url,
        description: 'Gets user activity logs',
      },
    },
  );
}

export function useAuditLogsGetUserProfileAuditLogsQuery<
  T = AuditLogsGetUserProfileAuditLogsResponse,
>(
  {
    sleekflow_user_profile_id,
    filters,
    limit = 10,
  }: Omit<AuditLogsGetUserProfileAuditLogsArgs, 'continuation_token'>,
  options?: {
    enabled?: boolean;
    select?:
      | ((data: AuditLogsGetUserProfileAuditLogsResponse) => T)
      | undefined;
  },
) {
  const url = 'AuditHub/AuditLogs/GetUserProfileAuditLogsV2';
  const axiosClient = useAxios();
  return useQuery({
    queryKey: auditHubKeys.getAuditLogsGetUserProfileAuditLogs(
      {
        sleekflow_user_profile_id,
        filters,
        limit,
      },
      {
        type: 'query',
      },
    ),
    queryFn: async ({ signal }) => {
      const response =
        await axiosClient.post<AuditLogsGetUserProfileAuditLogsResponse>(
          url,
          {
            sleekflow_user_profile_id,
            filters,
            limit,
          },
          {
            signal,
          },
        );
      return response.data;
    },
    enabled: options?.enabled,
    select: options?.select,
    meta: {
      url,
      description: 'Gets user activity logs',
    },
  });
}

type AuditLogsCreateStaffManualAddedLogArgs = {
  sleekflow_user_profile_id: string;
  audit_log_text: string;
};

export function useAuditLogsCreateStaffManualAddedLogMutation() {
  const axiosClient = useAxios();
  const url = 'AuditHub/AuditLogs/CreateStaffManualAddedLog';
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: AuditLogsCreateStaffManualAddedLogArgs) => {
      const response = axiosClient.post<AuditLogsCreateStaffManualAddedLogArgs>(
        url,
        {
          sleekflow_user_profile_id: data.sleekflow_user_profile_id,
          audit_log_text: data.audit_log_text,
        },
      );

      return response;
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(
        auditHubKeys.getAuditLogsGetUserProfileAuditLogs({
          sleekflow_user_profile_id: variables.sleekflow_user_profile_id,
          filters: {},
        }),
      );
    },
  });
}
