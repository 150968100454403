import { createContext, useContext, useRef } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

import type { ImportPreviewHeader } from '@/api/userProfile';
import { ImportPreviewResponse } from '@/api/userProfile';

export type ColumnsMap = Omit<ImportPreviewResponse, 'headers'> & {
  headers: Omit<ImportPreviewHeader, 'fieldType'>[];
};

const ContactsImportContext =
  createContext<StoreApi<ContactsImportStore> | null>(null);

type ContactsImportStore = {
  stepIndex: 0 | 1 | 2 | 3;
  stepTwo: {
    file: File | null;
    initColumnsMap: ImportPreviewResponse | null;
  };
  stepThree: {
    columnsMap: ColumnsMap | null;
  };
  stepFour: {
    isTriggerAutomation: boolean;
    importType: 'individual' | 'list';
    contactList: { label: string; value?: number } | null;
  };
  removeFile: () => void;
  setFile: (file: File) => void;
  setInitColumnsMap: (columnsMap: ImportPreviewResponse) => void;
  setColumnsMap: (columnsMap: ColumnsMap) => void;
  setStepFourForm: (payload: {
    isTriggerAutomation: boolean;
    importType: 'individual' | 'list';
    contactList: { label: string; value?: number } | null;
  }) => void;
  setStepIndex: (options: {
    stepIndex: 0 | 1 | 2 | 3;
    onReset?: (options: {
      currentStepIndex: 0 | 1 | 2 | 3;
      nextStepIndex: 0 | 1 | 2 | 3;
    }) => void;
  }) => void;
  resetStepTwo: () => void;
  resetStepThree: () => void;
  reset: () => void;
};

export const ContactsImportProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const storeRef = useRef<StoreApi<ContactsImportStore> | null>(null);

  if (!storeRef.current) {
    storeRef.current = createStore<ContactsImportStore>((set) => ({
      stepIndex: 0,
      stepTwo: {
        file: null,
        initColumnsMap: null,
      },
      stepThree: {
        columnsMap: null,
      },
      stepFour: {
        isTriggerAutomation: false,
        importType: 'individual',
        contactList: null,
      },
      removeFile: () =>
        set((state) => ({
          stepTwo: {
            ...state.stepTwo,
            file: null,
          },
        })),
      setFile: (file) =>
        set((state) => ({
          stepTwo: {
            ...state.stepTwo,
            file: file,
          },
        })),
      setInitColumnsMap: (columnsMap) => {
        set((state) => ({
          stepTwo: {
            ...state.stepTwo,
            initColumnsMap: columnsMap,
          },
        }));
      },
      setColumnsMap: (columnsMap) => {
        set((state) => ({
          stepThree: {
            ...state.stepThree,
            columnsMap: columnsMap,
          },
        }));
      },
      setStepFourForm: (payload) => {
        set(() => ({
          stepFour: payload,
        }));
      },
      setStepIndex: ({ stepIndex, onReset }) => {
        set((state) => {
          onReset?.({
            currentStepIndex: state.stepIndex,
            nextStepIndex: stepIndex,
          });
          return {
            stepIndex: stepIndex,
          };
        });
      },
      resetStepTwo: () => {
        set(() => ({
          stepTwo: {
            file: null,
            initColumnsMap: null,
          },
        }));
      },
      resetStepThree: () => {
        set(() => ({
          stepThree: {
            columnsMap: null,
          },
        }));
      },
      reset: () => {
        set(() => ({
          stepIndex: 0,
          stepTwo: {
            file: null,
            initColumnsMap: null,
          },
          stepThree: {
            columnsMap: null,
          },
          stepFour: {
            isTriggerAutomation: false,
            importType: 'individual',
            contactList: null,
          },
        }));
      },
    }));
  }

  return (
    <ContactsImportContext.Provider value={storeRef.current}>
      {children}
    </ContactsImportContext.Provider>
  );
};

function useContactsImport<T>(selector: (store: ContactsImportStore) => T) {
  const store = useContext(ContactsImportContext);
  if (!store) {
    throw new Error(
      'useContactsImport must be used within ContactsImportProvider',
    );
  }
  return useStore(store, selector);
}

export default useContactsImport;

export type FormattedColumnsMap = ImportPreviewHeader & {
  previewInformation: string;
};
