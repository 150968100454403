import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';

import { useCompany } from '@/api/company';
import type { BillRecord } from '@/api/types';
import AppLoading from '@/components/AppLoading';
import Navbar from '@/components/Navbar';
import PushNotification from '@/components/PushNotification';
import {
  PLAN_NAMES_REGEX,
  SUBSCRIPTION_TIER,
  type SubscriptionPlanName,
} from '@/constants/subscription-plans';
import { useMyProfile } from '@/hooks/useMyProfile';

export default function AuthenticatedLayout() {
  useAnalytics();

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=0.1" />
      </Helmet>
      <Box display="flex">
        <Navbar />
        <PushNotification />
        <Box
          component="main"
          flex={1}
          height="100svh"
          width="100vw"
          overflow="hidden"
        >
          <Suspense fallback={<AppLoading withoutNavbar />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </>
  );
}

function useAnalytics() {
  const location = useLocation();
  const staffInfo = useMyProfile();
  const company = useCompany({
    select: ({ id, companyName, billRecords }) => {
      const activeBillRecord =
        fromApiBillRecordsToActiveBillRecord(billRecords);

      return {
        id,
        companyName,
        subscriptionPlan: transformPlanDetails(
          activeBillRecord?.subscriptionPlan?.id,
        ).planName,
      };
    },
  });

  useEffect(() => {
    if (!staffInfo.data || !company.data) return;

    const { firstName, lastName, displayName } = staffInfo.data.userInfo;

    const username =
      firstName && lastName ? `${firstName} ${lastName}` : displayName;

    // Sentry
    Sentry.setUser({
      id: staffInfo.data.userInfo.id,
      companyId: company.data.id,
      userId: staffInfo.data.userInfo.id,
      username,
      email: staffInfo.data.userInfo.email,
      staffId: staffInfo.data.staffId,
      associatedTeams: JSON.stringify(staffInfo.data.associatedTeams),
      userRole: staffInfo.data.userInfo.userRole,
      createdAt: staffInfo.data.userInfo.createdAt,
      roleType: staffInfo.data.roleType,
      locale: staffInfo.data.locale,
      timeZoneInfo: JSON.stringify(staffInfo.data.timeZoneInfo),
      status: staffInfo.data.status,
    });

    // Mixpanel
    mixpanel.identify(staffInfo.data.userInfo.id);
    mixpanel.register({
      $email: staffInfo.data.userInfo.email,
      Role: staffInfo.data.userInfo.userRole,
      'Subscription Plan': company.data.subscriptionPlan,
      Platform: 'Web v2',
    });
    mixpanel.people.set({
      'Company ID': company.data.id,
      'Company Name': company.data.companyName,
      $email: staffInfo.data.userInfo.email,
      $name: username,
      Username: username,
    });
    mixpanel.track('Session Initialized');

    return () => {
      mixpanel.reset();
    };
  }, [staffInfo.data, company.data]);

  // Track page view since Mixpanel doesn't track it automatically on SPA
  useEffect(() => {
    if (!staffInfo.data || !company.data) return;

    const { email, userRole } = staffInfo.data.userInfo;
    // Wait and add user info manually to skip the initial page view tracking that has no user info
    mixpanel.track_pageview({
      $email: email,
      Role: userRole,
      'Subscription Plan': company.data.subscriptionPlan,
      Platform: 'Web v2',
    });
  }, [location.pathname, staffInfo.data, company.data]);
}

function fromApiBillRecordsToActiveBillRecord(billRecords: BillRecord[]) {
  const activeBillRecordsWithoutAddOns = billRecords
    .filter((record) => {
      return (
        record.subscriptionPlan &&
        [SUBSCRIPTION_TIER.addOn, SUBSCRIPTION_TIER.agent].indexOf(
          record.subscriptionPlan.subscriptionTier,
        ) === -1 &&
        dayjs.utc(record.periodEnd).isAfter(dayjs.utc())
      );
    })
    .sort((a, b) => (dayjs(a.periodEnd).isAfter(b.periodEnd) ? 1 : -1))
    .sort((a, b) => {
      return (
        a.subscriptionPlan.subscriptionTier -
        b.subscriptionPlan.subscriptionTier
      );
    });

  return activeBillRecordsWithoutAddOns.length > 0
    ? activeBillRecordsWithoutAddOns[activeBillRecordsWithoutAddOns.length - 1]
    : undefined;
}

function transformPlanDetails(planName: string | undefined) {
  if (!planName) {
    return {
      planName: 'startup',
      billingPeriod: null,
    };
  }

  let transformedPlanName: SubscriptionPlanName | null = null;
  let planPeriod: 'monthly' | 'yearly' | null = null;

  Object.values(PLAN_NAMES_REGEX).forEach((value) => {
    if (value.regex.test(planName)) {
      transformedPlanName = value.name;
      if ('period' in value) {
        planPeriod = value.period;
      }
    }
  });

  return {
    planName: transformedPlanName as SubscriptionPlanName | null,
    billingPeriod: planPeriod as 'monthly' | 'yearly' | null,
  };
}
