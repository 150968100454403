import { useAuth0 } from '@auth0/auth0-react';
import { Button, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useRouteError } from 'react-router-dom';

import AccessDeniedErrorElement from '@/components/AccessDeniedErrorElement';
import ServerErrorElement from '@/components/ServerErrorElement';
import { ROUTES } from '@/constants/navigation';
import useBackToURL from '@/hooks/useBackToURL';
import { useMyProfile } from '@/hooks/useMyProfile';
import { useRouteWithLocale } from '@/hooks/useRouteWithLocale/useRouteWithLocale';

import { isFlowBuilderError } from './FlowBuilderError';

const FlowBuilderErrorElement = () => {
  const error = useRouteError();
  const backToURL = useBackToURL({ fallback: ROUTES.flowBuilder });

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const userProfile = useMyProfile();
  const { logout } = useAuth0();
  const routeTo = useRouteWithLocale();

  if (isFlowBuilderError(error) && error.status === 403) {
    return (
      <AccessDeniedErrorElement
        title={
          error.title ||
          t('flow-builder.error.access-denied.title', {
            defaultValue: 'Access Denied',
          })
        }
        description={
          error.description ||
          t('flow-builder.error.access-denied.description', {
            defaultValue:
              "You don't have permission to access this page. \n" +
              'Please contact your admin for support.',
          })
        }
        actions={
          <Button
            to={routeTo(ROUTES.inbox)}
            component={Link}
            variant="contained"
            onClick={() => {
              queryClient.resetQueries({
                predicate: (query) => !!query.state.error,
              });
            }}
          >
            {t('flow-builder.error.access-denied.back-button', {
              defaultValue: 'Back to Inbox',
            })}
          </Button>
        }
        message={
          <Typography
            variant="body2"
            sx={{
              color: 'grey.90',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              textAlign: 'center',
            }}
          >
            <Trans
              i18nKey="flow-builder.error.access-denied.signed-in-as"
              values={{ email: userProfile.data?.userInfo.email }}
              defaults="You're currently signed in as <2>{{email}}</2>. <br/> Try <4>signing in</4> with another email to access this page."
            >
              You're currently signed in as{' '}
              <strong>{userProfile.data?.userInfo.email}</strong>. Try
              <Typography
                component="span"
                sx={{ color: 'blue.90', cursor: 'pointer' }}
                onClick={() => {
                  mixpanel.reset();
                  logout({ returnTo: window.location.origin });
                }}
              >
                signing in
              </Typography>
              with another email to access this page.
            </Trans>
          </Typography>
        }
      />
    );
  }

  return (
    <ServerErrorElement
      actions={
        <Button
          sx={{
            mt: '24px',
          }}
          component={Link}
          variant="contained"
          to={backToURL}
          onClick={() => {
            queryClient.resetQueries({
              predicate: (query) => !!query.state.error,
            });
          }}
        >
          {t('broadcast.error.back-button', {
            defaultValue: 'Back',
          })}
        </Button>
      }
    />
  );
};

export default FlowBuilderErrorElement;
