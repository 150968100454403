import { LogoutOptions } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Divider,
  ListItemText,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppearOnlineDict, useStaffProfileMutation } from '@/api/company';
import { ROUTES } from '@/constants/navigation';
import { useMyProfile } from '@/hooks/useMyProfile';
import { useRouteWithLocale } from '@/hooks/useRouteWithLocale/useRouteWithLocale';
import useSnackbar from '@/hooks/useSnackbar';
import { colors } from '@/themes';
import { generateV1RedirectionLink } from '@/utils/v1-utils';

import { AccountSettingsPanel } from '.';
import Icon from '../Icon';
import StaffAvatar from '../StaffAvatar';
import isAccountAppearOnline from './helpers/isAccountAppearOnline';
import { ListItemButton, MenuList } from './styled';

export default function MainPanel({
  name,
  email,
  goTo,
  logout,
}: {
  name: string;
  email: string;
  goTo: (panel: AccountSettingsPanel) => void;
  logout: (options: LogoutOptions) => void;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const snackbar = useSnackbar();
  const profile = useMyProfile();
  const routeTo = useRouteWithLocale();
  const { mutate: mutateStaffProfile } = useStaffProfileMutation({
    onError: () => {
      snackbar.error(t('show-agent-name-failed'));
    },
  });

  if (!profile.data) {
    return null;
  }

  return (
    <>
      <Box p="16px">
        <Stack direction="row" alignItems="center" spacing="12px" mb="12px">
          <StaffAvatar userId={profile.data?.userInfo.id} alt={name} />
          <Stack>
            <Typography variant="headline3">{name}</Typography>
            <Typography variant="body2">{email}</Typography>
          </Stack>
        </Stack>
        <Button
          variant="outlined"
          fullWidth
          component={Link}
          to={routeTo(ROUTES.settings)}
        >
          {t('manage-account')}
        </Button>
      </Box>
      <Divider />
      <MenuList
        aria-labelledby="preferences-list"
        subheader={
          <ListHeader id="preferences-list">{t('preferences')}</ListHeader>
        }
      >
        <Tooltip
          title={t('appear-online-toggle-tip', {
            defaultValue:
              "This controls the user's activity status. When set to 'away,' user will not be automatically assigned to new conversations by the queue.",
          })}
          placement="left-start"
        >
          <ListItemButton
            onClick={() => {
              mutateStaffProfile({
                status: isAccountAppearOnline(profile.data!.status)
                  ? AppearOnlineDict.Away
                  : AppearOnlineDict.Active,
                userId: profile.data!.userInfo.id,
              });
            }}
          >
            {isAccountAppearOnline(profile.data!.status) ? (
              <ListItemText
                primary={
                  <Typography variant="menu1">
                    {t('appear-online-set-offline', {
                      defaultValue: 'Set status to away',
                    })}
                  </Typography>
                }
              />
            ) : (
              <ListItemText
                primary={
                  <Typography variant="menu1">
                    {t('appear-online-set-online', {
                      defaultValue: 'Set status to online',
                    })}
                  </Typography>
                }
              />
            )}
          </ListItemButton>
        </Tooltip>
        <Tooltip title={t('agent-name-toggle-tip')} placement="left-start">
          <ListItemButton>
            <ListItemText
              primary={
                <Typography variant="menu1">
                  {t('agent-name-toggle')}
                </Typography>
              }
            />

            <Switch
              onChange={() => {
                mutateStaffProfile({
                  isShowName: !profile.data!.isShowName,
                  userId: profile.data!.userInfo.id,
                });
              }}
              checked={profile.data?.isShowName}
            />
          </ListItemButton>
        </Tooltip>

        <ListItemButton onClick={() => goTo(AccountSettingsPanel.LANGUAGE)}>
          <Icon icon="globe" size={16} />
          <ListItemText
            sx={{ ml: '8px' }}
            primary={
              <Typography variant="menu1">{t('language-title')}</Typography>
            }
          />
          <Icon icon="chevron-right" size={20} />
        </ListItemButton>
      </MenuList>
      <Divider />
      <MenuList
        aria-labelledby="account-settings-list"
        subheader={
          <ListHeader id="account-settings-list">
            {t('general-setting-title')}
          </ListHeader>
        }
      >
        <ListItemButton
          //temp redirect to v1, Remove it when v2 page is ready
          href={generateV1RedirectionLink({
            path: '/settings/plansubscription',
            language,
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemText
            primary={<Typography variant="menu1">{t('billing')}</Typography>}
          />
          <Icon icon="chevron-right" size={20} />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            mixpanel.reset();
            logout({ returnTo: window.location.origin });
          }}
        >
          <ListItemText
            primary={<Typography variant="menu1">{t('log-out')}</Typography>}
          />
          <Icon icon="chevron-right" size={20} />
        </ListItemButton>
        <ListItemButton
          component="a"
          href={generateV1RedirectionLink({
            path: '/',
            language,
          })}
          rel="nofollow noopener"
        >
          <ListItemText
            primary={
              <Typography variant="menu1">
                {t('switch-to-sleekflow-v1')}
              </Typography>
            }
          />
        </ListItemButton>
      </MenuList>
    </>
  );
}

function ListHeader({
  id,
  children,
}: {
  id: string;
  children: React.ReactNode;
}) {
  return (
    <Box id={id} p="16px" pb="8px">
      <Typography
        variant="headline5"
        sx={{ textTransform: 'uppercase', color: colors.grey80 }}
      >
        {children}
      </Typography>
    </Box>
  );
}
