import { RoleType } from '@/api/types';
import { useMyProfile } from '@/hooks/useMyProfile';

export const useFlowBuilderRoleBasedAccessControl = ({
  suspense,
}: { suspense?: boolean } = {}) => {
  const userProfile = useMyProfile({ suspense });

  return {
    // Flow Builder
    canUseFlow: userProfile.data?.roleType === RoleType.ADMIN,
  };
};
