import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { useAxios } from '@/api/axiosClient';
import { Country } from '@/api/types';

export const commonKeys = createQueryKeys('common', {
  getCountryList: null,
  getCurrentIp: null,
});

interface IpResponseType {
  ipAddress: string;
}

export const useGetCountryListQuery = <T = Country[]>({
  select,
}: {
  select?: (data: Country[]) => T;
} = {}) => {
  const url = '/country';
  const axiosClient = useAxios();

  return useQuery(
    commonKeys.getCountryList,
    async ({ signal }) => {
      const result = await axiosClient.get(url, { signal });
      return result.data;
    },
    {
      select,
    },
  );
};

export const useGetCurrentIpQuery = () => {
  const url = '/ipaddress';
  const axiosClient = useAxios();

  return useQuery(commonKeys.getCurrentIp, async () => {
    const response = await axiosClient.get<IpResponseType>(url, {
      skipAuth: true,
    });
    if (response.data) {
      return response.data;
    }
    throw new Error('useGetCurrentIpQuery error');
  });
};
